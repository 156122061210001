import { eventQBus } from "../types/EventQBus";
import { OverflowBoxItem } from "../overflowBox/OverflowBox";
import StringHelper from "../util/StringHelper";
import type { TrackingLabels } from "../tracking/TrackingLabels";
import { submitMoveFilterFeature } from "../filter/FilterTracking";
import { isMobile } from "../util/Breakpoint";
import { AvContent } from "../multifiltering/AvContent";

export type SortingSubmitEvent = CustomEvent<{
  submitter: HTMLElement;
  createPi: boolean;
  whatIf?: TrackingLabels["san_FilterWhatIf"];
}>;

declare global {
  interface GlobalEventHandlersEventMap {
    sortingSubmit: SortingSubmitEvent;
  }
}

export class SortingSubmitAction implements EventListenerObject {
  /*                  */

  static sortingSubmitEvent(
    submitter: HTMLElement,
    createPi = false,
    whatIf?: TrackingLabels["san_FilterWhatIf"]
  ): SortingSubmitEvent {
    return new CustomEvent("sortingSubmit", {
      bubbles: true,
      cancelable: true,
      detail: { submitter, createPi, whatIf },
    });
  }

  /*               */

  static register() {
    document.addEventListener("sortingSubmit", new SortingSubmitAction(), {
      passive: true,
    });
  }

  /*                 */

  handleEvent(event: SortingSubmitEvent) {
    if (event.defaultPrevented) {
      return;
    }

    const form = <HTMLFormElement>event.target;
    const formData = new FormData(form);
    const rule = formData.get("rule") as string;
    const sortiertnach = formData.get("sortiertnach") as string;
    const l = formData.get("l") as string;
    const initiallyVisible = OverflowBoxItem.isVisibleIfCollapsed(event.detail.submitter);
    const shouldScroll = isMobile();

    if (event.detail.createPi) {
      eventQBus.emit(
        "tracking.bct.trackOnNextPageImpression",
        SortingSubmitAction.trackOnNextPiLabels(sortiertnach, initiallyVisible, true, event.detail.whatIf)
      );
      submitMoveFilterFeature(form, () => true);
      eventQBus.emit("ftfind.dresonRule.resolve", { formData });
    } else {
      eventQBus.emit("ftfind.dresonRule.resolve", {
        rule: AvContent.pageRule() || rule,
        page: {
          o: "",
          l,
          sortiertnach,
        },
        shouldScroll,
        tsLink: SortingSubmitAction.trackOnNextPiLabels(sortiertnach, initiallyVisible, false, event.detail.whatIf),
      });
    }
  }

  private static trackOnNextPiLabels(
    sortiertnach: string,
    initiallyVisible: boolean,
    createPi = false,
    whatIf?: TrackingLabels["san_FilterWhatIf"]
  ): Partial<TrackingLabels> {
    return {
      san_Interaction: createPi ? "sorting_change_filter_add" : "sorting_change",
      san_SortingChange: sortiertnach,
      ts_RemoveLabels: "wk.nav_UnfilteredSelectionRule",
      san_SortingInitialVisible: StringHelper.toBooleanString(initiallyVisible),
      ...(whatIf && { san_FilterWhatIf: whatIf }),
    };
  }
}
