import { FacetGroupSelect } from "./FacetGroupSelect";
import { eventQBus } from "../types/EventQBus";

export class FacetGroupSelectAction implements EventListenerObject {
  /*               */
  constructor(
    readonly select: FacetGroupSelect,
    private readonly initialFacetGroup = select.selectedOption?.value
  ) {}

  /*                  */

  static on(select: FacetGroupSelect, listener = new FacetGroupSelectAction(select)) {
    select.select.addEventListener("change", listener, {
      passive: true,
    });
    select.select.form?.addEventListener("reset", listener, {
      passive: true,
    });
  }

  /*                  */

  handleEvent(event: Event) {
    if (event.target instanceof HTMLSelectElement) {
      const facetGroup = event.target.value;
      const updated = this.select.activateFacetGroup(facetGroup);

      if (updated) {
        eventQBus.emit("tracking.bct.submitEvent", {
          san_DefaultFacetChange: facetGroup,
          san_DefaultFacet: this.initialFacetGroup || "none",
        });
      }
    } else {
      /*              */
      this.select.activateFacetGroup(this.initialFacetGroup);
    }
  }
}
