import { eventQBus } from "../types/EventQBus";
import {
  filter,
  filterSheetButton,
  filterTitle,
  menuBackButton,
  menuBarButtonForm,
  quickFilterButton,
} from "../filtersheet/Selectors";
import { forEachElement } from "../util/Utils";
import { FilterAccordion } from "../filter/FilterAccordion";
import type { TrackingLabels } from "./TrackingLabels";
import { mergeToLegacyLabel } from "./FeatureTracking";

const FACET_VALUE_TAG_FORM_SELECTOR = ".find_facetValueTags form";

export function updateWhatIfTracking(sanFilterWhatIf: TrackingLabels["san_FilterWhatIf"]) {
  eventQBus.emit("tracking.bct.addToPageImpression", {
    san_FilterWhatIf: sanFilterWhatIf,
  });
  updateSanFilterWhatIf(filterSheetButton(), sanFilterWhatIf);
  updateSanFilterWhatIf(quickFilterButton(), sanFilterWhatIf);
  updateSanFilterWhatIf(menuBarButtonForm(), sanFilterWhatIf);
  forEachElement(FACET_VALUE_TAG_FORM_SELECTOR, (elem) => updateSanFilterWhatIf(elem, sanFilterWhatIf));
  FilterAccordion.factory.forEach((accordion) => updateFilter(accordion, sanFilterWhatIf));
}

function updateFilter(accordion: FilterAccordion, sanFilterWhatIf: TrackingLabels["san_FilterWhatIf"]) {
  const filterId = accordion.filterId;
  updateSanFilterWhatIf(accordion.accordion, sanFilterWhatIf);
  updateSanFilterWhatIf(filterTitle(filterId), sanFilterWhatIf);
  const filterForm = filter(filterId);
  updateSanFilterWhatIf(filterForm, sanFilterWhatIf);
  updateSanFilterWhatIf(menuBackButton(filterForm), sanFilterWhatIf);
}

function updateSanFilterWhatIf(element: HTMLElement | null, san_FilterWhatIf: TrackingLabels["san_FilterWhatIf"]) {
  if (element) {
    mergeToLegacyLabel(element, "san_FilterWhatIf", san_FilterWhatIf);
  }
}
