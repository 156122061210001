import { HeurekaElementFactory } from "../util/HeurekaElementFactory";
import { eventQBus } from "../types/EventQBus";
import type { FilterSectionLoadedEvent } from "../multifiltering/FilterTypes";
import { appendAll } from "../util/Utils";
import { FilterSorting } from "./FilterSorting";
import { FilterAccordion } from "./FilterAccordion";
import { Filter } from "./Filter";
import type { FilterSortingOld } from "./FilterSortingOld";

const DESKTOP_FILTERS_CLASS = "find_filterList";
const DENSE_FILTERS_CLASS = "heureka_denseList";
const DENSE_FILTERS_SELECTOR = `.${DENSE_FILTERS_CLASS}`;
const EXPANDED_FILTERS_CLASS = "heureka_expandedList";
const EXPANDED_FILTERS_SELECTOR = `.${EXPANDED_FILTERS_CLASS}`;

export class FilterAccordions {
  private static readonly factory = HeurekaElementFactory.byClass(DESKTOP_FILTERS_CLASS, FilterAccordions);

  /*               */
  constructor(
    private readonly accordionsWrapper: HTMLElement,
    readonly accordions: FilterAccordion[] = FilterAccordion.factory.all(accordionsWrapper),
  ) {}

  /*                  */

  static template(rootElement?: ParentNode | null) {
    return FilterAccordions.factory.pick(undefined, rootElement);
  }

  /*               */

  static register() {
    eventQBus.on("heureka.filters.loaded", FilterAccordions.initAll);
  }

  static initAll(event: FilterSectionLoadedEvent, rootElement?: ParentNode) {
    FilterAccordions.template(rootElement)?.init();
  }

  protected init() {
    this.updateFeaturesPositions();
    return this;
  }

  /*                       */

  public get values(): HTMLElement[] {
    return this.accordions.map((filterAccordion) => filterAccordion.accordion);
  }

  private set values(filtersToSort: HTMLElement[]) {
    appendAll(filtersToSort, this.accordionsWrapper);
  }

  private get denseFiltersWrapper(): HTMLElement | null {
    return this.accordionsWrapper.querySelector(DENSE_FILTERS_SELECTOR);
  }

  private set denseModeValues(denseFilterAccordions: HTMLElement[]) {
    appendAll(denseFilterAccordions, this.denseFiltersWrapper);
  }

  private get expandedFiltersWrapper(): HTMLElement | null {
    return this.accordionsWrapper.querySelector(EXPANDED_FILTERS_SELECTOR);
  }

  private set expandedModeValues(expandedFilterAccordions: HTMLElement[]) {
    appendAll(expandedFilterAccordions, this.expandedFiltersWrapper);
  }

  get filters(): Filter[] {
    return this.accordions.map((accordion) => accordion.filter).filter((filter) => !!filter) as Filter[];
  }

  updateFeaturesPositions() {
    this.filters
      .map((filter) => filter.clearPosition())
      .filter((filter) => filter.trackable && !filter.hidden)
      .forEach((filter: Filter, index) => {
        filter.position = index + 1;
      });
  }

  sortValuesOld(sorter: FilterSortingOld) {
    const sortedFilters = sorter.sortFiltersByQuery(this.values);
    if (sortedFilters) {
      this.values = sortedFilters;
    }
  }

  private filterAccordionsByMode(sortedAccordions: FilterAccordion[], isDenseMode: boolean): FilterAccordion[] {
    return sortedAccordions.filter((acc) => acc.filter && acc.filter.isVisibleInDenseMode === isDenseMode);
  }

  sortValues(sorter: FilterSorting) {
    const sortedAccordions = sorter.sortAccordionsByQuery(this.accordions);
    const denseAccordions = this.filterAccordionsByMode(sortedAccordions, true);
    const expandedAccordions = this.filterAccordionsByMode(sortedAccordions, false);
    if (denseAccordions && denseAccordions.length > 0) {
      this.denseModeValues = denseAccordions.map((acc) => acc.accordion);
    }
    if (expandedAccordions && expandedAccordions.length > 0) {
      this.expandedModeValues = expandedAccordions.map((acc) => acc.accordion);
    }
  }
  closeAllAccordionsExcept(excludedAccordion: FilterAccordion) {
    this.accordions.forEach((accordion: FilterAccordion) => {
      accordion.open = accordion.filterId === excludedAccordion.filterId;
    });
  }
}
