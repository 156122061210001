import { HeurekaElementFactory } from "../util/HeurekaElementFactory";

const FACET_VALUE_TAG_CLASS = `find_facetValueTags__item`;
const FACET_VALUE_TAG_FAVORITE_ICON_CLASS = ".heureka_facetValueTag--favoriteIcon";

export class FacetValueTag {
  static readonly factory = HeurekaElementFactory.byElement("li", FACET_VALUE_TAG_CLASS, FacetValueTag);

  /*               */
  constructor(readonly facetValueTag: HTMLLIElement) {}

  /*                  */

  /*               */

  /*                       */

  get button(): HTMLButtonElement | null {
    return this.facetValueTag.querySelector("button");
  }

  get tsFeatureRef(): string | undefined {
    return this.button?.dataset.tsFeatureRef;
  }

  get span(): HTMLSpanElement | null {
    return this.facetValueTag.querySelector("label span");
  }

  get title(): string | null {
    return this.span?.textContent || null;
  }

  get favoriteIcon(): HTMLElement | null {
    return this.facetValueTag.querySelector(FACET_VALUE_TAG_FAVORITE_ICON_CLASS);
  }

  set favoriteIconVisibility(visible: boolean) {
    if (this.favoriteIcon) {
      this.favoriteIcon.hidden = !visible;
    }
  }
}
