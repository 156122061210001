import { eventQBus } from "../../types/EventQBus";
import { fireFilterSubmit, unlockSubmitButtons } from "../FilterFormActions";
import type { TrackingLabels } from "../../tracking/TrackingLabels";
import DelayedAction from "../../util/DelayedAction";
import { FacetValue } from "../FacetValue";
import { element, isDesktopDevice } from "../../util/Utils";
import { HeurekaElementFactory } from "../../util/HeurekaElementFactory";
import { updateFacetValueLabel } from "../FilterTracking";
import type { ExtendedChangeEvent } from "../SubmitOnChange";
import { isAnyActive } from "../../toggle/Toggle";

const SLIDER_HIDDEN_INPUT_FIELD_CLASS = "ts_heureka_sliderHiddenInput";
const SLIDER_HIDDEN_INPUT_FIELD_SELECTOR = `.${SLIDER_HIDDEN_INPUT_FIELD_CLASS}`;
const SLIDER_HIDDEN_SUBMIT_FIELD_CLASS = "ts_heureka_sliderHiddenSubmit";
const SLIDER_HIDDEN_SUBMIT_FIELD_SELECTOR = `.${SLIDER_HIDDEN_SUBMIT_FIELD_CLASS}`;
const PALI_SLIDER_CLASS = "heureka_slider";
const PALI_SLIDER_SELECTOR = `.${PALI_SLIDER_CLASS}`;
const SLIDER_CLASS = "ts_heureka_slider";
const SLIDER_SELECTOR = `.${SLIDER_CLASS}`;

const MINMAX_FIELD_SELECTOR = "input[type=number]";
const MINMAX_RANGE_SELECTOR = "input[type=range]";

export class Slider {
  static readonly factory = HeurekaElementFactory.byClass(SLIDER_CLASS, Slider);

  /*               */
  constructor(
    readonly elem: HTMLElement,
    readonly form: HTMLFormElement | null = elem.closest("form"),
    readonly slider: HTMLElement | null = elem.querySelector(PALI_SLIDER_SELECTOR),
    readonly fieldset: HTMLFieldSetElement | null = slider?.closest("fieldset") || null,
    readonly sliderField: HTMLInputElement | null = elem.querySelector<HTMLInputElement>(
      SLIDER_HIDDEN_INPUT_FIELD_SELECTOR,
    ) || null,
    readonly submitField: HTMLInputElement | null = elem.querySelector<HTMLInputElement>(
      SLIDER_HIDDEN_SUBMIT_FIELD_SELECTOR,
    ) || null,
    readonly minField: HTMLInputElement | null = slider?.querySelectorAll<HTMLInputElement>(MINMAX_FIELD_SELECTOR)[0] ||
      null,
    readonly minRangeSelector: HTMLInputElement | null = slider?.querySelectorAll<HTMLInputElement>(
      MINMAX_RANGE_SELECTOR,
    )[0] || null,
    readonly maxField: HTMLInputElement | null = slider?.querySelectorAll<HTMLInputElement>(MINMAX_FIELD_SELECTOR)[1] ||
      null,
    readonly maxRangeSelector: HTMLInputElement | null = slider?.querySelectorAll<HTMLInputElement>(
      MINMAX_RANGE_SELECTOR,
    )[1] || null,
  ) {}

  /*                  */
  static fromId(sliderId: string, root?: ParentNode) {
    return Slider.factory.declare(element(`#${sliderId}`, root)?.closest(SLIDER_SELECTOR));
  }

  /*               */

  static register() {
    eventQBus.on("heureka.sliders.initialised", Slider.#initAll);
  }

  static #initAll() {
    Slider.factory.all().forEach((slider) => slider.init());
  }

  static init({ id }: { id: string }) {
    Slider.fromId(id)?.init();
  }

  private init() {
    if (this.elem.dataset.heurekaSliderInitialized) {
      /*                   */
      return;
    }
    this.elem.dataset.heurekaSliderInitialized = "true";

    if (this.slider) {
      this.initializeAutoSubmit();

      this.initializeInputField(this.minField, this.minRangeSelector, 0);
      this.initializeSliderRange();
      this.initializeInputField(this.maxField, this.maxRangeSelector, 1);

      if (isAnyActive("HEUREKA_1153_CHIPS_WITH_PRICE_RANGES", "HEUREKA_1153_CHIPS_WITH_PRICE_RANGES_E3131")) {
        this.initializeUpperRangeChips();
      }
    }
    return this;
  }

  private initializeAutoSubmit() {
    if (this.form && this.slider) {
      const delayedAction = DelayedAction.create(this.form, () => this.fireSliderAutoSubmit("adopt_delayed"));

      const doCancel = delayedAction.cancel.bind(delayedAction);
      const doDelay = delayedAction.delay.bind(delayedAction, {});
      const doDelayNoWait = delayedAction.delay.bind(delayedAction, {
        delayInMillis: 0,
        actionToPerform: () => this.fireSliderAutoSubmit(), /*                          */
      });
      const doThrottle = delayedAction.throttle.bind(delayedAction, {
        throttleInMillis: 50,
      });
      const options = { passive: true };

      /*                                                                                                  */
      this.slider.addEventListener("touchstart", doCancel, options);
      this.slider.addEventListener("mousedown", doCancel, options);
      this.slider.addEventListener("touchend", doDelay, options);
      this.slider.addEventListener("mouseup", doDelay, options);

      this.slider.addEventListener("focusin", doCancel, options);
      this.slider.addEventListener("focusout", doDelayNoWait, options);

      /*                                                                                                    */
      /*                                                                                                    */
      /*                                               */
      if (isDesktopDevice()) {
        this.form.addEventListener(
          "mousedown",
          (event) => {
            if (FacetValue.isFacetValueUserInteraction(event)) doThrottle();
          },
          options,
        );
      }

      this.form.addEventListener(
        "click",
        (event) => {
          if (event.target instanceof HTMLInputElement && event.target.type !== "range") doCancel();
        },
        options,
      );

      this.form.addEventListener("filterSubmit", doCancel, options);
      this.form.addEventListener("reset", doCancel, options);
      this.form.addEventListener("submit", () => (this.autoSubmitAllowed = false), options);

      this.form.addEventListener(
        "change",
        () => {
          if (!this.chipSelected && !this.sliderSelected && this.submitField) this.submitField.value = "_,_";
        },
        options,
      );
    }
  }

  private initializeInputField(
    inputField: HTMLInputElement | undefined | null,
    singleSlider: HTMLInputElement | undefined | null,
    limitIndex: number,
  ) {
    if (this.slider && inputField) {
      const limitKey = limitIndex === 0 ? "heurekaSliderInitialMinValue" : "heurekaSliderInitialMaxValue";
      const limitValue = this.slider.dataset[limitKey];
      const limitType = limitIndex === 0 ? "min" : "max";
      const { facetName } = this.slider.dataset;
      if (limitValue) {
        inputField.value = limitValue;
        inputField.dispatchEvent(new Event("change"));
      }
      if (singleSlider) {
        singleSlider.addEventListener(
          "change",
          () => this.onInputFieldChanged(limitIndex, inputField, `slider_${limitType}`),
          {
            passive: true,
          },
        );
      }
      inputField.addEventListener("change", () => this.onInputFieldChanged(limitIndex, inputField, "interval"), {
        passive: true,
      });
      if (facetName) {
        inputField.addEventListener("focusin", this.trackFocus(`click_${limitType}`, facetName), {
          passive: true,
        });
        inputField.addEventListener("input", this.trackDelete(`delete_${limitType}`, facetName), {
          passive: true,
        });
      }
    }
  }

  private initializeSliderRange() {
    const sliderRange = this.slider?.querySelector<HTMLDivElement>(MINMAX_RANGE_SELECTOR)?.closest("div");
    sliderRange?.addEventListener(
      "click",
      () => {
        if (this.minField && this.minField.value != this.slider?.dataset.sliderMinVal) {
          this.onInputFieldChanged(0, this.minField, "slider_min");
        }
        if (this.maxField && this.maxField.value != this.slider?.dataset.sliderMaxVal) {
          this.onInputFieldChanged(1, this.maxField, "slider_max");
        }
      },
      {
        passive: true,
      },
    );
  }

  private initializeUpperRangeChips() {
    this.rangeChips.forEach((chip) => {
      chip.input?.addEventListener("change", (event) => this.onUpperRangeChipChanged(event, chip), {
        passive: true,
      });
    });
  }

  private fireSliderAutoSubmit(filterMethod?: string) {
    /*                                                         */
    if (this.form && this.form.closest(".heureka_filterTitle__values") && this.autoSubmitAllowed) {
      if (filterMethod) {
        this.form.dataset.tsFeatureFilterMethod = filterMethod;
      }
      this.autoSubmitAllowed = false;
      return fireFilterSubmit(this.form);
    }
    return false;
  }

  private onUpperRangeChipChanged(event: ExtendedChangeEvent, chip: FacetValue) {
    if (chip.input) {
      /*                       */
      this.rangeChips.filter((other) => other.value !== chip.value).forEach((other) => (other.checked = false));
      chip.checked = true;
      chip.input.dataset.tsFeatureFilterMethod = event.filterMethod || "popular";
      this.updateSliderFromUpperRangeChip(chip);
    }
  }

  private updateSliderFromUpperRangeChip(chip: FacetValue) {
    if (this.submitField && this.sliderField) {
      const oldSliderValue = this.sliderField.value;
      const newValue = (chip.value || "_,_").split(",");

      const newUpperLimit = Number(newValue.at(1) || "_"); /*                                 */
      const oldLowerLimit = Number(oldSliderValue.split(",").at(0) || "_");
      const newLowerLimit =
        !isNaN(oldLowerLimit) && !isNaN(newUpperLimit) && oldLowerLimit > newUpperLimit ? NaN : oldLowerLimit;

      /*                                             */
      Slider.exchangeLimits(this.submitField, newLowerLimit, newUpperLimit);

      /*                                                                      */
      Slider.exchangeLimits(this.sliderField, newLowerLimit, NaN);

      /*                                                   */
      if (oldSliderValue !== this.sliderField.value) {
        updateFacetValueLabel(this.sliderField);
      }
    }
  }

  private onInputFieldChanged(
    limitIndex: number,
    target: HTMLInputElement,
    filterMethod: TrackingLabels["san_FilterMethod"],
  ) {
    if (
      limitIndex === 1 &&
      isAnyActive("HEUREKA_1153_CHIPS_WITH_PRICE_RANGES", "HEUREKA_1153_CHIPS_WITH_PRICE_RANGES_E3131")
    ) {
      const chip = this.rangeChips.find((chip) => chip.value === "_," + target.value);
      if (chip && chip.input) {
        const event: ExtendedChangeEvent = new Event("change", { bubbles: true });
        event.filterMethod = filterMethod;
        chip.input.dispatchEvent(event);
        return;
      } else {
        this.rangeChips.forEach((chip) => (chip.checked = false));
      }
    }

    this.updateSliderFromInputField(limitIndex, target, filterMethod);
  }

  private updateSliderFromInputField(
    limitIndex: number,
    target: HTMLInputElement,
    filterMethod: TrackingLabels["san_FilterMethod"],
  ) {
    if (this.submitField && this.sliderField && this.form && this.slider) {
      this.autoSubmitAllowed = true;
      const oldFieldValue = this.sliderField.value;
      const newValue = Number(target.value || "_");

      /*                                               */
      Slider.exchangeLimit(this.submitField, limitIndex, newValue);
      Slider.exchangeLimit(this.sliderField, limitIndex, newValue);

      /*                                                                  */
      if (oldFieldValue !== this.sliderField.value) {
        /*                                                                                 */
        unlockSubmitButtons.bind(this.form)();
        this.sliderField.dataset.heurekaSliderLastMethod = filterMethod;
        if (filterMethod === "slider_max" || filterMethod === "slider_min") {
          const availablePreselectsStr = this.slider.dataset.heurekaSliderAvailablePreselects;
          if (availablePreselectsStr) {
            const availablePreselects = Number(availablePreselectsStr);
            if (availablePreselects) {
              this.slider.dataset.heurekaSliderAvailablePreselects = `${availablePreselects - 1}`;
            } else {
              this.sliderField.dataset.doNotSubmitPreselect = "true";
            }
          }
        }

        this.sliderField.dispatchEvent(new Event("change", { bubbles: true }));
      }
    }
  }

  /*                       */

  get minLabel(): string {
    return this.slider?.dataset.sliderMinLabel || "";
  }

  get maxLabel(): string {
    return this.slider?.dataset.sliderMaxLabel || "";
  }

  get minValue(): string {
    return this.slider?.dataset.sliderMinVal || "";
  }

  get maxValue(): string {
    return this.slider?.dataset.sliderMaxVal || "";
  }

  get facetName(): string {
    return this.slider?.dataset.facetName || "";
  }

  get initialMinValue(): string {
    return this.slider?.dataset.heurekaSliderInitialMinValue || "";
  }

  get initialMaxValue(): string {
    return this.slider?.dataset.heurekaSliderInitialMaxValue || "";
  }

  get availablePreselects(): string {
    return this.slider?.dataset.heurekaSliderAvailablePreselects || "";
  }

  get logarithmic(): string {
    return this.slider?.dataset.sliderLogarithmic || "";
  }

  get delayedActionMillis(): string {
    return this.slider?.dataset.delayedActionMillis || "";
  }

  get showDefaultPlaceholders(): string {
    return this.slider?.dataset.sliderShowDefaultPlaceholders || "";
  }

  get focusFloatsBothLabels(): string {
    return this.slider?.dataset.sliderFocusFloatsBothLabels || "";
  }

  get id(): string {
    return this.slider?.id || "";
  }

  get rangeChips(): FacetValue[] {
    if (this.fieldset) {
      return FacetValue.factory.all(this.fieldset).filter((chip) => chip.input?.disabled === false);
    }
    return [];
  }

  get chipSelected(): boolean {
    return this.rangeChips.find((chip) => chip.checked) !== undefined;
  }

  get sliderSelected(): boolean {
    return this.sliderField?.value !== "_,_";
  }

  set autoSubmitAllowed(value: boolean) {
    if (this.form) this.form.dataset["autoSubmit"] = value ? "allowed" : "forbidden";
  }

  get autoSubmitAllowed(): boolean {
    return this.form?.dataset["autoSubmit"] !== "forbidden";
  }

  /*       */

  private trackFocus(
    sanFacetActivity: TrackingLabels["san_FacetActivity"],
    sanFacetActivityType: TrackingLabels["san_FacetActivityType"],
  ): EventListener {
    return () => {
      eventQBus.emit("tracking.bct.submitEvent", {
        san_FacetActivity: sanFacetActivity,
        san_FacetActivityType: sanFacetActivityType,
      });
    };
  }

  private trackDelete(
    sanFacetActivity: TrackingLabels["san_FacetActivity"],
    sanFacetActivityType: TrackingLabels["san_FacetActivityType"],
  ): EventListener {
    return (event) => {
      const target = event.target;
      if (target instanceof HTMLInputElement && !target.value) {
        eventQBus.emit("tracking.bct.submitEvent", {
          san_FacetActivity: sanFacetActivity,
          san_FacetActivityType: sanFacetActivityType,
        });
      }
    };
  }

  private static exchangeLimit(input: HTMLInputElement, limitIndex: number, limit: number) {
    const oldLowerLimit = Number((input.value || "_,_").split(",").at(0));
    const oldUpperLimit = Number((input.value || "_,_").split(",").at(1));

    const newUpperLimit = limitIndex == 1 ? limit : oldUpperLimit;
    const newLowerLimit = limitIndex == 0 ? limit : oldLowerLimit;

    input.value = (isNaN(newLowerLimit) ? "_" : newLowerLimit) + "," + (isNaN(newUpperLimit) ? "_" : newUpperLimit);
  }

  private static exchangeLimits(input: HTMLInputElement, newLowerLimit: number, newUpperLimit: number) {
    input.value = (isNaN(newLowerLimit) ? "_" : newLowerLimit) + "," + (isNaN(newUpperLimit) ? "_" : newUpperLimit);
  }
}
