import { isAppEnvironment, isSamsungBrowser, mapElements } from "../util/Utils";
import { updateLegacyLabel } from "../tracking/FeatureTracking";
import { eventQBus } from "../types/EventQBus";
import { filterSheetButton } from "../filtersheet/Selectors";
import { isMobile } from "../util/Breakpoint";

let buttons: QuickFilterButton[];
const VISIBILITY_OFFSET_FALLBACK = 200;

export class QuickFilterButton {
  private readonly visibilityOffset: number;
  private readonly handler: EventListener;

  private constructor(private readonly elem: HTMLElement) {
    this.visibilityOffset = QuickFilterButton.calculateVisibilityOffset();
    this.handler = () => this.setVisibility();
  }

  public static create(elem: HTMLElement) {
    const button = new QuickFilterButton(elem);
    button.init();
    return button;
  }

  public addScrollListener() {
    window.addEventListener("scroll", this.handler);
    return this;
  }

  public removeScrollListener() {
    window.removeEventListener("scroll", this.handler);
    return this;
  }

  private init() {
    this.elem.addEventListener("click", QuickFilterButton.onClick, {
      capture: true,
      passive: true,
    });
    this.setVisibility(); /*                     */
  }

  private setVisibility() {
    const scrollpos = window.scrollY;
    let shouldHide = true;
    if (isMobile()) {
      shouldHide = scrollpos < this.visibilityOffset;
    }

    if (this.elem.hidden !== shouldHide) {
      this.elem.hidden = shouldHide;
      if (!shouldHide) {
        eventQBus.emit("tracking.bct.submitEvent", {
          san_FilterQuickFilterAction: "shown",
        });
      }
    }
  }

  private static calculateVisibilityOffset() {
    const element = filterSheetButton();
    if (element) {
      const visibilityOffset = element.getBoundingClientRect().bottom;
      return visibilityOffset <= 0 ? VISIBILITY_OFFSET_FALLBACK : visibilityOffset;
    }
    return VISIBILITY_OFFSET_FALLBACK;
  }

  private static onClick(event: Event) {
    const target = event.currentTarget as HTMLElement;
    updateLegacyLabel(target, "wk.san_FilterQuickFilterPosition", `${window.scrollY}`);
  }
}

export function registerQuickFilterButtonHandlers() {
  const isApp = isAppEnvironment();
  if (!isApp) {
    eventQBus.on("heureka.filters.loaded", onFilterSectionLoaded);
    eventQBus.on("heureka.filterSection.load", onFilterSectionLoad);
  }
  eventQBus.on("ftfind.tile.compared", increaseBottomSpace);
  eventQBus.on("ftfind.comparison.canceled", decreaseBottomSpace);
  increaseBottomSpaceForSamsung();
}

function onFilterSectionLoad() {
  buttons.map((button) => button.removeScrollListener());
}

function increaseBottomSpace() {
  document.body.classList.add("extraBottomSpace");
}

function decreaseBottomSpace() {
  document.body.classList.remove("extraBottomSpace");
}

function increaseBottomSpaceForSamsung() {
  if (isSamsungBrowser() && !isAppEnvironment()) {
    document.body.classList.add("extraBottomSpace");
  }
}

function onFilterSectionLoaded() {
  buttons = mapElements(".js_san_quickFilter_button", QuickFilterButton.create).map((button) =>
    button.addScrollListener(),
  );
}
