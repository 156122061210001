import type { Appendable } from "../util/Appendable";
import { HeurekaElementFactory } from "../util/HeurekaElementFactory";
import { clone, forEachElement } from "../util/Utils";
import { eventQBus } from "@otto-ec/global-resources/event-q-bus";
import { FacetValue } from "../filter/FacetValue";
import type { TrackingLabels } from "../tracking/TrackingLabels";
import { fireFilterSubmit } from "../filter/FilterFormActions";
import { updateLabel, updateStatus } from "../tracking/FeatureTracking";
import { isMobile } from "../util/Breakpoint";
import { Filter } from "../filter/Filter";
import { Facet } from "../filter/Facet";
import { setChangeLabels } from "../filter/FilterTracking";

const RETAILER_FILTER_ID = `verkaeufer`;
const RETAILER_FACET_ID = `verkaeufer`;
const OTTO_RETAILER_FACET_VALUE_ID = `otto`;
const TEMPLATE_ID = `heureka_ottoSwitchBannerTemplate`;
const SELECTOR_TEMPLATE = `#${TEMPLATE_ID}`;
const ID_WRAPPER = `heureka_ottoSwitchBanner`;
const CLASS_BANNER = `heureka_ottoSwitchBanner`;
const CLASS_SWITCH = `${CLASS_BANNER}__switch`;
const SELECTOR_SWITCH = `.${CLASS_SWITCH}`;
const SELECTOR_BANNER = `.${CLASS_BANNER}`;
const SELECTOR_TEMPLATE_BANNER = `${SELECTOR_TEMPLATE} ${SELECTOR_BANNER}`;
export const FACET_LOCATION_LIST_INITIAL: Partial<TrackingLabels> = {
  san_FacetLocation: "list_initial",
};
const SESSION_STORAGE_KEY = "heureka_ottoSwitchBannerDisabled";

export default class OttoSwitchBanner implements Appendable {
  static readonly factory = HeurekaElementFactory.byClass(CLASS_BANNER, OttoSwitchBanner);

  /*               */

  constructor(
    readonly banner: HTMLElement,
    readonly wrapper: HTMLElement | null = document.getElementById(ID_WRAPPER),
    readonly toggle: HTMLInputElement | null = banner.querySelector(SELECTOR_SWITCH),
    readonly retailerForm: HTMLFormElement | undefined = Filter.filterId(RETAILER_FILTER_ID)?.form,
    readonly retailerFacet: HTMLFieldSetElement | undefined = Facet.facetId(RETAILER_FACET_ID)?.facet,
    readonly retailerFacetValue: FacetValue | undefined = FacetValue.facetValue(
      RETAILER_FACET_ID,
      OTTO_RETAILER_FACET_VALUE_ID,
    ),
  ) {}

  /*                  */

  static fromTemplate(rootElement?: ParentNode | null): OttoSwitchBanner | undefined {
    return OttoSwitchBanner.factory.pick(SELECTOR_TEMPLATE_BANNER, rootElement);
  }

  /*               */

  static register() {
    /*                                                                          */
  }

  private init() {
    const retailerForm = this.retailerForm;
    if (this.retailerFacetValue && retailerForm && !retailerForm.hidden) {
      /*                   */
      document.querySelector(".find_refinementBar")?.classList.add("find_refinementBar--banner");
      this.setChecked(this.retailerFacetValue.checked);
      this.toggle?.addEventListener("click", (event) => {
        const target = event.target as HTMLInputElement;
        if (target) {
          this.updateRetailerValue(target.checked);
          fireFilterSubmit(retailerForm);
        }
      });
      this.banner.addEventListener("oc-close", () => this.onCloseClick());
      this.visible = !sessionStorage.getItem(SESSION_STORAGE_KEY);
      this.updateRetailerFeatureTrackingStatus();
    } else {
      this.visible = false; /*                   */
    }
    return this;
  }

  private updateRetailerFeatureTrackingStatus() {
    if (this.visible && isMobile() && this.retailerForm) {
      updateStatus(this.retailerForm, "visible");
      if (this.retailerFacet && !this.retailerFacet.hidden) {
        updateStatus(this.retailerFacet, "visible");
      }
    }
  }

  private updateRetailerValue(checked: boolean) {
    forEachElement<HTMLInputElement>(
      `input[name=${RETAILER_FACET_ID}][value=${OTTO_RETAILER_FACET_VALUE_ID}]`,
      (input) => {
        updateLabel(input, "san_FilterMethod", "switch");
        if (this.retailerForm) {
          setChangeLabels(this.retailerForm, FACET_LOCATION_LIST_INITIAL);
        }
        input.checked = checked;
      },
    );
  }

  private onCloseClick() {
    this.visible = false;
    sessionStorage.setItem(SESSION_STORAGE_KEY, "true");
    this.activityTracking("close_banner");
  }

  /*                       */

  appendTo(elem: HTMLElement): void {
    elem.appendChild(this.banner);
  }

  clone() {
    return OttoSwitchBanner.factory.create(clone(this.banner)).init();
  }

  get visible(): boolean {
    return !this.banner.hidden && this.banner.childElementCount > 0;
  }

  set visible(visibility: boolean) {
    this.banner.hidden = !visibility;
    if (this.wrapper) {
      this.wrapper.hidden = !visibility;
    }
  }

  get checked(): boolean {
    return this.toggle?.checked || false;
  }

  setChecked(status: boolean) {
    if (this.toggle) {
      this.toggle.checked = status;
    }
  }

  /*       */

  private activityTracking(activity: TrackingLabels["san_FacetActivity"]) {
    eventQBus.emit("tracking.bct.submitEvent", {
      san_FacetActivity: activity,
      san_FacetActivityType: this.retailerFacetValue?.name,
    });
  }
}
