import type { ActionName, EventMergeId, Feature, FeatureName, FeatureStatus } from "./Tracking";
import { filterElements, getElementById, mapElements } from "../util/Utils";
import type { TrackingLabels } from "./TrackingLabels";
import { eventQBus } from "../types/EventQBus";

/**
 *
 *
 *
 *
 */
export type ElementPredicate<E extends HTMLElement> = (elem: E) => boolean;

/**
 *
 *
 *
 *
 *
 */
export function or<E extends HTMLElement>(
  first: ElementPredicate<E> | ((elem?: E) => boolean),
  second: ElementPredicate<E> | ((elem?: E) => boolean)
): ElementPredicate<E> {
  return (elem) => first(elem) || second(elem);
}

/**
 *
 *
 *
 *
 *
 */
export function hasStatus(elem: HTMLElement): boolean {
  return !!elem.dataset.tsFeatureStatus;
}

/**
 *
 *
 *
 *
 */
export function referencedFeature<E extends HTMLElement>(elem: E): E | undefined {
  return getElementById<E>(elem.dataset.tsFeatureRef);
}

/**
 *
 *
 *
 *
 *
 */
export function dereference<E extends HTMLElement>(elem: E): E {
  const target = referencedFeature(elem);
  return target ? copyLabels(elem, target) : elem;
}

/**
 *
 *
 *
 *
 *
 *
 */
export function findFeatures<E extends HTMLElement>(
  elem: ParentNode | FeatureName[],
  filter: ElementPredicate<E>,
  selector = ""
): E[] {
  return Array.isArray(elem)
    ? Array.prototype.concat.apply(
        [],
        elem.map((name) => filterElements(`[data-ts-feature-name="${name}"]${selector}`, filter))
      )
    : mapElements<E, E>(`[data-ts-feature-ref]${selector}`, dereference, elem)
        .filter(filter)
        .concat(filterElements(`[data-ts-feature-name]${selector}`, filter, elem));
}

/**
 *
 *
 *
 *
 *
 */
export type IncludeFeatureCallback = (feature: Feature) => boolean;

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
function listFeatures(elem: ParentNode | FeatureName[], include?: IncludeFeatureCallback): Feature[] {
  return findFeatures(elem, (elem) => hasStatus(elem) || isAlwaysTracked(elem))
    .map((item) => {
      const {
        tsFeatureStatus,
        tsFeatureName,
        tsFeatureParentId,
        tsFeaturePosition,
        tsFeatureLabels = "{}",
      } = item.dataset;
      return {
        id: item.id,
        name: tsFeatureName,
        status: tsFeatureStatus || "visible",
        ...(tsFeatureParentId && { parentId: tsFeatureParentId }),
        ...(tsFeaturePosition && { position: parseInt(tsFeaturePosition) }),
        labels: JSON.parse(tsFeatureLabels) as Partial<TrackingLabels>,
      } as Feature;
    })
    .filter(include || (() => true));
}

function isAlwaysTracked(elem: HTMLElement) {
  return elem?.classList.contains("ts_heureka_alwaysTrack");
}

/**
 *
 *
 *
 */
export function clearStatus(elem: HTMLElement) {
  delete elem.dataset.tsFeatureStatus;
}

/**
 *
 *
 *
 *
 */
function setStatus(elem: HTMLElement, status?: FeatureStatus) {
  if (status) {
    elem.dataset.tsFeatureStatus = status;
  } else {
    clearStatus(elem);
  }
}

/**
 *
 *
 *
 *
 */
export function getStatus(elem: HTMLElement): FeatureStatus | undefined {
  return elem.dataset.tsFeatureStatus as FeatureStatus | undefined;
}

/**
 *
 *
 *
 *
 *
 */
export type MergeStatusCallback = (
  oldStatus: FeatureStatus | undefined,
  newStatus: FeatureStatus | undefined
) => FeatureStatus | undefined;

/**
 *
 *
 *
 *
 */
export type SiblingStatusCallback = (oldStatus?: FeatureStatus) => FeatureStatus | undefined;

/**
 *
 *
 *
 *
 *
 *
 *
 */
export function updateStatus(
  elem: HTMLElement,
  status?: FeatureStatus,
  parents?: number,
  mergeStatus?: MergeStatusCallback,
  siblingStatus?: SiblingStatusCallback
) {
  setStatus(elem, mergeStatus ? mergeStatus(getStatus(elem), status) : status);
  if (siblingStatus) {
    /*                                       */
    filterElements(
      `[data-ts-feature-name="${elem.dataset.tsFeatureName}"]`,
      (item: HTMLElement) => item.id !== elem.id,
      getElementById(elem.dataset.tsFeatureParentId)
    ).forEach((item) => setStatus(item, siblingStatus(getStatus(item))));
  }
  if (parents) {
    const parentElem = getElementById(elem.dataset.tsFeatureParentId);
    if (parentElem) {
      updateStatus(parentElem, status, parents - 1, mergeStatus);
    }
  }
}

/**
 *
 *
 *
 *
 */
export function updatePosition(elem: HTMLElement, position?: number) {
  if (position) {
    elem.dataset.tsFeaturePosition = position.toString();
  } else {
    delete elem.dataset.tsFeaturePosition;
  }
}

/**
 *
 *
 *
 *
 */
export function setLabels(elem: HTMLElement, labels: Partial<TrackingLabels>) {
  elem.dataset.tsFeatureLabels = JSON.stringify(labels);
}

/**
 *
 *
 *
 *
 *
 */
export function updateLabel<T extends keyof TrackingLabels>(elem: HTMLElement, label: T, value?: TrackingLabels[T]) {
  const { tsFeatureLabels = "{}" } = elem.dataset;
  const tsFeatureLabelData = JSON.parse(tsFeatureLabels);
  if (value !== undefined) {
    tsFeatureLabelData[label] = [value];
  } else if (Object.prototype.hasOwnProperty.call(tsFeatureLabelData, label)) {
    delete tsFeatureLabelData[label];
  }
  setLabels(elem, tsFeatureLabelData);
}

/**
 *
 *
 *
 *
 */
export function updateLabels(elem: HTMLElement, values: Partial<TrackingLabels>) {
  const { tsFeatureLabels = "{}" } = elem.dataset;
  const tsFeatureLabelData = JSON.parse(tsFeatureLabels);
  Object.entries(values).forEach((v) => (tsFeatureLabelData[v[0]] = [v[1]]));
  setLabels(elem, tsFeatureLabelData);
}

/**
 *
 *
 *
 *
 *
 */
export function readLabel<T extends keyof TrackingLabels>(elem: HTMLElement, label: T): TrackingLabels[T] | undefined {
  const { tsFeatureLabels = "{}" } = elem.dataset;
  const tsFeatureLabelData = JSON.parse(tsFeatureLabels);
  if (tsFeatureLabelData[label]) {
    const [value] = tsFeatureLabelData[label];
    return value;
  }
  return undefined;
}

/**
 *
 *
 *
 *
 *
 */
export function copyLabels<E extends HTMLElement, S extends E>(source: S, target: E): E {
  /*                                            */
  if (source == target) {
    /*                                                                  */
    /*                         */
    return target;
  }

  const { tsFeatureLabels: sourceLabels } = source.dataset;
  if (sourceLabels) {
    const { tsFeatureLabels: targetLabels = "{}" } = target.dataset;
    setLabels(target, {
      ...JSON.parse(targetLabels),
      ...JSON.parse(sourceLabels),
    });
  }
  return target;
}

/**
 *
 *
 *
 *
 */
export function setLegacyLabels(elem: HTMLElement, labels: Partial<TrackingLabels>) {
  elem.dataset.tsLabels = JSON.stringify(labels);
}

/**
 *
 *
 *
 *
 *
 */
export function updateLegacyLabel<T extends keyof TrackingLabels>(
  elem: HTMLElement,
  label: T,
  value?: TrackingLabels[T]
) {
  const { tsLabels = "{}" } = elem.dataset;
  const tsLabelData = JSON.parse(tsLabels);
  if (value) {
    tsLabelData[label] = value;
  } else if (Object.prototype.hasOwnProperty.call(tsLabelData, label)) {
    delete tsLabelData[label];
  }
  setLegacyLabels(elem, tsLabelData);
}

/**
 *
 *
 *
 *
 *
 */
export function mergeToLegacyLabel<T extends keyof TrackingLabels>(
  elem: HTMLElement,
  label: T,
  value: TrackingLabels[T]
) {
  const { tsLabels = "{}" } = elem.dataset;
  const tsLabelData = JSON.parse(tsLabels);
  const oldValue = tsLabelData[label];
  tsLabelData[label] = oldValue ? `${oldValue}|${value}` : value;
  setLegacyLabels(elem, tsLabelData);
}

/**
 *
 *
 *
 *
 *
 */
export function readLegacyLabel<T extends keyof TrackingLabels>(
  elem: HTMLElement,
  label: T
): TrackingLabels[T] | undefined {
  const { tsLabels = "{}" } = elem.dataset;
  const tsLabelData = JSON.parse(tsLabels);
  return tsLabelData[label];
}

/**
 *
 *
 *
 *
 */
export function readLegacyLabels(elem: HTMLElement): Partial<TrackingLabels> {
  const { tsLabels = "{}" } = elem.dataset;
  return JSON.parse(tsLabels);
}

/**
 *
 *
 *
 *
 *
 */
export function copyLegacyLabels<S extends HTMLElement, T extends HTMLElement>(source: S, target: T): T {
  const { tsLabels: sourceLabels } = source.dataset;
  if (sourceLabels) {
    const { tsLabels: targetLabels = "{}" } = target.dataset;
    setLegacyLabels(target, {
      ...JSON.parse(targetLabels),
      ...JSON.parse(sourceLabels),
    });
  }
  return target;
}

/**
 *
 *
 *
 *
 */
export function addFeaturesToPageImpression(elem: ParentNode | FeatureName[], include?: IncludeFeatureCallback) {
  const features = listFeatures(elem, include);
  return eventQBus.emit("tracking.bct.addFeaturesToPageImpression", features);
}

/**
 *
 *
 *
 *
 *
 *
 */
export function submitAction(
  elem: ParentNode | FeatureName[],
  name: ActionName,
  labels: Partial<TrackingLabels> | EventMergeId = {},
  include?: IncludeFeatureCallback
) {
  const features = listFeatures(elem, include);
  const action = {
    name: name,
    features: features,
  };
  if (typeof labels === "string") {
    eventQBus.emit("tracking.bct.addActionToEvent", action, labels);
  } else {
    eventQBus.emit("tracking.bct.submitAction", labels, action);
  }
}

/**
 *
 *
 *
 *
 *
 *
 */
export function submitMoveAction(
  elem: ParentNode | FeatureName[],
  name: ActionName,
  labels: Partial<TrackingLabels> = {},
  include?: IncludeFeatureCallback
) {
  const features = listFeatures(elem, include);
  const action = {
    name: name,
    features: features,
  };
  eventQBus.emit("tracking.bct.submitMoveAction", labels, action);
}

/**
 *
 */
export function deriveAction(elem: HTMLElement): ActionName {
  const { tsFeatureStatus } = elem.dataset;
  switch (tsFeatureStatus) {
    case "added":
      return "add";
    case "deleted":
      return "delete";
    case "preselected":
      return "preselect";
    case "selected":
      return "select";
    case "predeselected":
      return "predeselect";
    case "deselected":
      return "deselect";
    case "changed":
      return "change";
    default:
      return "click";
  }
}

/**
 *
 */
export function deriveCommonAction(elem: HTMLElement[]): ActionName {
  return elem
    .map(deriveAction)
    .reduce((previousValue, currentValue) => (previousValue === currentValue ? previousValue : "change"));
}
