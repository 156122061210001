import { eventQBus } from "../types/EventQBus";
import { clear, element, forEachElement } from "../util/Utils";
import { SortingForm } from "../sorting/SortingForm";
import { FacetValueTags } from "../filter/FacetValueTags";
import NextBestActionBanner from "../nextBestAction/NextBestActionBanner";
import { isAnyActive } from "../toggle/Toggle";
import { getActiveVariation } from "../experiment/Experiments";
import type { Appendable } from "../util/Appendable";
import NextBestActionFacetValueTags from "../nextBestAction/NextBestActionFacetValueTags";
import SizesPersonalizationBanner from "../personalization/SizesPersonalizationBanner";
import OttoSwitchBanner from "../switchBanner/OttoSwitchBanner";

const CLASS_REFINEMENT_BAR = "find_refinementBar";
const SELECTOR_REFINEMENT_BAR = `.${CLASS_REFINEMENT_BAR}`;

const ID_SORTING_FORM_ANCHOR = "heureka_desktopSorting";
const SELECTOR_SORTING_FORM_ANCHOR = `#${ID_SORTING_FORM_ANCHOR}`;
const ID_FACET_VALUE_TAGS_ANCHOR = "find_facetValueTags";
const SELECTOR_FACET_VALUE_TAGS_ANCHOR = `#${ID_FACET_VALUE_TAGS_ANCHOR}`;
const ID_OTTO_SWITCH_ANCHOR = "heureka_ottoSwitchBanner";
const SELECTOR_OTTO_SWITCH_ANCHOR = `#${ID_OTTO_SWITCH_ANCHOR}`;

export class RefinementBar {
  /*               */
  protected constructor(
    private readonly sortingFormAnchor: HTMLElement | null,
    private readonly facetValueTagsAnchor: HTMLElement | null,
    private readonly ottoSwitchAnchor: HTMLElement | null,
  ) {}

  static #create(elem: HTMLElement) {
    /*                                               */
    if (getActiveVariation("e3245", "StatusQuo") === "Toggle") {
      if (!document.getElementById("heureka_ottoSwitchBanner") && document.querySelector(SELECTOR_REFINEMENT_BAR)) {
        const ottoSwitchBannerAnchor = document.createElement("div");
        ottoSwitchBannerAnchor.id = "heureka_ottoSwitchBanner";
        document.querySelector(SELECTOR_REFINEMENT_BAR)?.append(ottoSwitchBannerAnchor);
      }
    }
    return new RefinementBar(
      element(SELECTOR_SORTING_FORM_ANCHOR, elem),
      element(SELECTOR_FACET_VALUE_TAGS_ANCHOR, elem),
      element(SELECTOR_OTTO_SWITCH_ANCHOR, elem),
    );
  }

  /*                  */

  static forEach(callback: (input: RefinementBar, index: number) => void, rootElement?: ParentNode) {
    forEachElement<HTMLElement>(
      SELECTOR_REFINEMENT_BAR,
      (elem, index) => callback(RefinementBar.#create(elem), index),
      rootElement,
    );
  }

  /*               */

  static lockAll() {
    RefinementBar.forEach((refinementBar) => refinementBar.lock());
  }

  static unlockAll() {
    RefinementBar.forEach((refinementBar) => refinementBar.unlock());
  }

  static register() {
    eventQBus.on("ftfind.tilelist.loaded", RefinementBar.initAll);
    eventQBus.on("heureka.filterSection.loaded", RefinementBar.initAll);
    eventQBus.on("heureka.nextBestAction.loaded", RefinementBar.initAll);
    eventQBus.on("heureka.filterPersonalizationBanner.loaded", RefinementBar.initAll);

    /*      */
    eventQBus.on("ftfind.dresonRule.resolve", RefinementBar.lockAll);

    /*        */
    eventQBus.on("heureka.refinementBar.loaded", RefinementBar.unlockAll);
  }

  static initAll(event?: unknown, rootElement?: ParentNode) {
    RefinementBar.forEach((refinementBar) => refinementBar.init(), rootElement);
    eventQBus.emit("heureka.refinementBar.loaded");
  }

  init() {
    const form: SortingForm | undefined = SortingForm.template();
    if (form) {
      this.sortingForm = form.clone();
    }

    this.clearRefinementForm();
    this.clearOttoSwitchBanner();

    if (isAnyActive("HEUREKA_1142_PERSONALIZATION_SAVE_SIZES", "HEUREKA_1142_PERSONALIZATION_SAVE_SIZES_E3095")) {
      switch (getActiveVariation("e3095", "StatusQuo")) {
        case "SaveSizes":
          if (SizesPersonalizationBanner.mayVisible) {
            this.addToRefinementForm(SizesPersonalizationBanner.fromTemplate()?.clone());
          }
          break;
      }
    }

    if (isAnyActive("HEUREKA_1032_NEXT_BEST_ACTION", "HEUREKA_1032_NEXT_BEST_ACTION_E3049")) {
      switch (getActiveVariation("e3049", "StatusQuo")) {
        case "DirectNBA":
          this.addToRefinementForm(
            NextBestActionFacetValueTags.mayVisible
              ? NextBestActionFacetValueTags.fromTemplate()?.clone()
              : FacetValueTags.template()?.clone(),
          );
          break;
        case "BetaBann":
          this.addToRefinementForm(
            NextBestActionBanner.mayVisible
              ? NextBestActionBanner.fromTemplate()?.clone()
              : FacetValueTags.template()?.clone(),
          );
          break;
        default:
          this.addToRefinementForm(FacetValueTags.template()?.clone());
          break;
      }
    } else {
      this.addToRefinementForm(FacetValueTags.template()?.clone());
    }

    if (getActiveVariation("e3245", "StatusQuo") === "Toggle") {
      this.addToOttoSwitchBanner(OttoSwitchBanner.fromTemplate()?.clone());
    }
  }

  /*                       */

  public lock() {
    this.sortingFormAnchor?.classList.add("find_refinementBar--loading");
    this.facetValueTagsAnchor?.classList.add("find_refinementBar--loading");
    this.ottoSwitchAnchor?.classList.add("find_refinementBar--loading");
    return this;
  }

  public unlock() {
    this.sortingFormAnchor?.classList.remove("find_refinementBar--loading");
    this.facetValueTagsAnchor?.classList.remove("find_refinementBar--loading");
    this.ottoSwitchAnchor?.classList.remove("find_refinementBar--loading");
    return this;
  }

  set sortingForm(form: SortingForm) {
    const anchor = this.sortingFormAnchor;
    if (anchor) {
      clear(anchor);
      anchor.appendChild(form.form);
    }
  }

  private clearRefinementForm() {
    const anchor = this.facetValueTagsAnchor;
    if (anchor) {
      clear(anchor);
    }
  }

  private addToRefinementForm(form: Appendable | undefined) {
    const anchor = this.facetValueTagsAnchor;
    if (anchor && form) {
      form.appendTo(anchor);
    }
  }

  public get refinementForm(): HTMLElement | null {
    return this.facetValueTagsAnchor;
  }

  private addToOttoSwitchBanner(elem: Appendable | undefined) {
    const anchor = this.ottoSwitchAnchor;
    if (anchor && elem) {
      elem.appendTo(anchor);
    }
  }

  private clearOttoSwitchBanner() {
    const anchor = this.ottoSwitchAnchor;
    if (anchor) {
      clear(anchor);
    }
  }
}
