import "./showMore.scss";
import { HeurekaElementFactory } from "../util/HeurekaElementFactory";
import { eventQBus } from "@otto-ec/global-resources/event-q-bus";
import { PopularFacetValuesWrapper } from "../filter/PopularFacetValues";
import { ShowMoreLink } from "./ShowMoreLink";

const SHOW_MORE_SELECTOR = "heureka_showMore";
const SHOW_MORE_EXPANDED_CLASS = `${SHOW_MORE_SELECTOR}--expanded`;
const SHOW_MORE_HAS_NO_POPULAR_VALUES_CLASS = `${SHOW_MORE_SELECTOR}--hasNoPopValues`;

export class ShowMore {
  static readonly factory = HeurekaElementFactory.byClass(SHOW_MORE_SELECTOR, ShowMore);

  constructor(readonly elem: HTMLElement) {}

  /*               */

  static register() {
    eventQBus.on("heureka.filters.loaded", ShowMore.initAll);
  }

  static initAll() {
    ShowMore.factory.forEach((showMore) => {
      showMore.init();
    });
  }

  private init() {
    this.initOffset();
    this.elem.addEventListener("input", ShowMore.updateOffset);
    this.noPopularValues = this.popularValuesWrapper?.hidden ?? true;
  }

  private initOffset() {
    this.offset = this.popularValuesWrapper?.userVisiblePopularFacetValuesCount || 0;
  }

  public prepare(oldShowMoreButton?: ShowMore) {
    this.showMoreLink?.prepare();
    this.expanded = !!oldShowMoreButton?.expanded;
    this.offset = oldShowMoreButton?.offset || 0;
  }

  /*                       */

  get showMoreLink() {
    return ShowMoreLink.factory.pick(undefined, this.elem);
  }

  private get popularValuesWrapper() {
    return PopularFacetValuesWrapper.factory.pick(undefined, this.elem);
  }

  set offset(offset: number) {
    this.elem.dataset.heurekaShowMoreOffset = (8 - offset).toString();
  }

  get expanded(): boolean {
    return this.elem.classList.contains(SHOW_MORE_EXPANDED_CLASS);
  }

  set expanded(state: boolean) {
    this.elem.classList.toggle(SHOW_MORE_EXPANDED_CLASS, state);
  }

  set noPopularValues(state: boolean) {
    this.elem.classList.toggle(SHOW_MORE_HAS_NO_POPULAR_VALUES_CLASS, state);
  }

  /**
 *
 *
 *
 */
  public toggle(state = !this.expanded) {
    this.expanded = state;
    return state;
  }

  public expand() {
    return this.toggle(true);
  }

  public collapse() {
    return this.toggle(false);
  }

  private static updateOffset(event: Event) {
    ShowMore.factory.closest(event.currentTarget)?.initOffset();
  }
}
