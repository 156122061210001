import type { Appendable } from "../util/Appendable";
import { HeurekaElementFactory } from "../util/HeurekaElementFactory";
import { clone, element, isSearchResultPage } from "../util/Utils";
import { eventQBus } from "../types/EventQBus";
import { FacetValueTags } from "../filter/FacetValueTags";
import { Filter } from "../filter/Filter";
import { Facet } from "../filter/Facet";
import { PopularFacetValuesWrapper } from "../filter/PopularFacetValues";
import { UpdateReferencedAction } from "../filter/UpdateReferencedAction";
import { SubmitOnChangeListener } from "../filter/SubmitOnChange";
import { FacetValueTag } from "../filter/FacetValueTag";
import { filterContextId } from "../filter/FilterContextId";
import { isMobile } from "../util/Breakpoint";
import type { TrackingLabels } from "../tracking/TrackingLabels";
import { OverflowBox, OverflowBoxContainer, OverflowBoxItem } from "../overflowBox/OverflowBox";
import { referencedFeature, updateLabel } from "../tracking/FeatureTracking";
import StringHelper from "../util/StringHelper";
import { updateWhatIfTracking } from "../tracking/WhatIfTracking";

const CLASS_NEXT_BEST_ACTIONS = `heureka_nextBestActionFacetValueTags`;
const SELECTOR_NEXT_BEST_ACTIONS = `.${CLASS_NEXT_BEST_ACTIONS}`;
const NEXT_BEST_ACTIONS_MODE_MODIFIER_PREFIX = `${CLASS_NEXT_BEST_ACTIONS}--`;
const SELECTOR_TEMPLATE_NEXT_BEST_ACTION = `#heureka_nextBestActionTemplate ${SELECTOR_NEXT_BEST_ACTIONS}`;

const CHANGE_TRACKING_LABELS: Partial<TrackingLabels> = {
  san_FacetLocation: "list_initial",
  san_FilterMethod: "popular",
};

enum NextBestActionMode {
  withTags = "withTags",
  withoutTags = "withoutTags",
}

export default class NextBestActionFacetValueTags implements Appendable {
  private static readonly factory = HeurekaElementFactory.byClass(
    CLASS_NEXT_BEST_ACTIONS,
    NextBestActionFacetValueTags
  );

  private static popularFacetValuesWrapper: PopularFacetValuesWrapper | undefined;
  private static facetId: string | undefined;
  private static filterId: string | undefined;
  private static facetFormId: string | undefined;
  private static facetFound = false;

  constructor(readonly elem: HTMLElement) {}

  static fromTemplate(rootElement?: ParentNode | null): NextBestActionFacetValueTags | undefined {
    return NextBestActionFacetValueTags.factory.pick(SELECTOR_TEMPLATE_NEXT_BEST_ACTION, rootElement);
  }

  static register() {
    eventQBus.on("heureka.filterSection.loaded", NextBestActionFacetValueTags.initFacet);
    window.o_global.breakpoint.registerChangeListener(NextBestActionFacetValueTags.onBreakpointChange);
  }

  private static onBreakpointChange() {
    eventQBus.emit("heureka.nextBestAction.loaded");
  }

  private static initFacet() {
    NextBestActionFacetValueTags.facet = Filter.factory
      .all()
      .find((filter) => !filter.hidden && filter.mayNextBestActionFacet)?.nextBestActionFacet;
    NextBestActionFacetValueTags.tracking();
  }

  public clone(): NextBestActionFacetValueTags | undefined {
    return new NextBestActionFacetValueTags(clone(this.elem)).init();
  }

  appendTo(elem: HTMLElement): void {
    elem.appendChild(this.elem);
    this.recomputeSize();
    this.updateVisibilityTrackingLabels();
  }

  private init() {
    this.mode = NextBestActionMode.withoutTags;
    const originalPopularFormId: string | undefined = NextBestActionFacetValueTags.facetFormId;
    const originalPopularValuesWrapper: PopularFacetValuesWrapper | undefined =
      NextBestActionFacetValueTags.popularFacetValuesWrapper;

    if (originalPopularValuesWrapper && originalPopularFormId) {
      const popularValuesWrapper: PopularFacetValuesWrapper = originalPopularValuesWrapper.clone();
      this.preparePopularValues(popularValuesWrapper, originalPopularFormId);
      this.addPopularValues(popularValuesWrapper);
    }

    const itemList: HTMLUListElement | null = this.itemList;
    if (itemList) {
      const facetValueTags = FacetValueTags.template()?.clone();
      if (facetValueTags) {
        this.addFacetValueTags(facetValueTags, itemList);
        this.mode = NextBestActionMode.withTags;
      }
      OverflowBoxContainer.declareOverflowBoxContainer(this.elem)?.setContent(itemList);
    }

    return this;
  }

  private addFacetValueTags(facetValueTags: FacetValueTags, itemList: HTMLElement) {
    facetValueTags.facetValueTags.reverse().forEach((facetValueTag: FacetValueTag) => {
      itemList.prepend(facetValueTag.facetValueTag);
    });
    const deselectAllButton = facetValueTags.deselectAllButton;
    if (deselectAllButton) {
      itemList.appendChild(deselectAllButton);
    }
  }

  private addPopularValues(popularValuesWrapper: PopularFacetValuesWrapper) {
    this.elem.appendChild(popularValuesWrapper.popularFacetValuesWrapper);
  }

  private preparePopularValues(valuesWrapper: PopularFacetValuesWrapper, formId: string) {
    valuesWrapper.hidden = false;
    valuesWrapper.toggleChipsContainer(true, false);
    valuesWrapper.enableVisibleValues();

    /*                                                                     */
    PopularFacetValuesWrapper.factory.release(valuesWrapper.popularFacetValuesWrapper);
    UpdateReferencedAction.on(valuesWrapper.popularFacetValuesWrapper);
    SubmitOnChangeListener.onForForm(valuesWrapper.popularFacetValuesWrapper, formId, this.onNextBestActionClicked);
  }

  private onNextBestActionClicked() {
    if (NextBestActionFacetValueTags.filterId) {
      Filter.updateChangeTrackingLabels(NextBestActionFacetValueTags.filterId, CHANGE_TRACKING_LABELS);
    }
  }

  set mode(mode: NextBestActionMode) {
    for (const key of Object.keys(NextBestActionMode)) {
      this.elem.classList.remove(`${NEXT_BEST_ACTIONS_MODE_MODIFIER_PREFIX}${key}`);
    }
    this.elem.classList.add(`${NEXT_BEST_ACTIONS_MODE_MODIFIER_PREFIX}${mode}`);
  }

  get itemList(): HTMLUListElement | null {
    return element("ul", this.elem);
  }

  static get mayVisible(): boolean {
    return NextBestActionFacetValueTags.isValidNextBestActionPage() && NextBestActionFacetValueTags.facetFound;
  }

  static get popularValueAvailable(): boolean {
    return (NextBestActionFacetValueTags.popularFacetValuesWrapper?.visibleFacetValues.length || 0) > 0;
  }

  static get selectedFacet(): string | undefined {
    return NextBestActionFacetValueTags.facetId;
  }

  static set facet(facet: Facet | undefined) {
    if (facet === undefined && !NextBestActionFacetValueTags.facetFound) {
      return;
    }

    NextBestActionFacetValueTags.popularFacetValuesWrapper = facet?.popularFacetValuesWrapper;
    NextBestActionFacetValueTags.facetFormId = facet?.filter?.formId;
    NextBestActionFacetValueTags.facetId = facet?.id;
    NextBestActionFacetValueTags.filterId = facet?.filter?.id;
    NextBestActionFacetValueTags.facetFound = facet !== undefined;
  }

  public static isValidNextBestActionPage(): boolean {
    return filterContextId() === "mode" && isMobile() && isSearchResultPage();
  }

  /*       */

  private static updateFacetValueTagVisibilityTrackingLabels(facetValueTag: FacetValueTag, index: number) {
    const button = facetValueTag.button;
    if (button) {
      const facetValue = referencedFeature(button);
      if (facetValue) {
        const isVisible = OverflowBoxItem.isVisibleIfCollapsed(button);
        updateLabel(facetValue, "san_FacetValueTagVisible", StringHelper.toBooleanString(isVisible));
        updateLabel(facetValue, "san_FacetValueTagPosition", (index + 1).toString());
      }
    }
  }

  private updateVisibilityTrackingLabels() {
    const itemList = this.itemList;
    if (itemList) {
      FacetValueTag.factory.forEach(NextBestActionFacetValueTags.updateFacetValueTagVisibilityTrackingLabels, itemList);
    }
  }

  private static tracking() {
    if (NextBestActionFacetValueTags.isValidNextBestActionPage()) {
      const visibleWithPopularValues =
        NextBestActionFacetValueTags.mayVisible && NextBestActionFacetValueTags.popularValueAvailable;
      updateWhatIfTracking(`e3049_${visibleWithPopularValues}`);
      NextBestActionFacetValueTags.trackSanTopQuickFacet();
    }
  }

  private static trackSanTopQuickFacet() {
    eventQBus.emit("tracking.bct.addToPageImpression", {
      san_TopQuickFacet: NextBestActionFacetValueTags.facetId || "none",
    });
  }

  private recomputeSize() {
    const itemList = this.itemList;
    if (itemList) {
      OverflowBox.factory.declare(itemList)?.initializeCollapsedState(true);
    }
  }
}
