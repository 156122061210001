import "./sizesPersonalization.scss";
import { eventQBus } from "../types/EventQBus";
import { Filter } from "../filter/Filter";
import { FacetValue } from "../filter/FacetValue";
import { isMobile } from "../util/Breakpoint";
import type { TrackingLabels } from "../tracking/TrackingLabels";
import { Storage } from "@otto-ec/global-resources/storage";
import type { FilterPersonalizationSaveEvent, FilterPersonalizationSelectEvent } from "../multifiltering/FilterTypes";
import { filterContextId } from "../filter/FilterContextId";
import StringHelper from "../util/StringHelper";
import { updateLabel } from "../tracking/FeatureTracking";
import { FacetValueTag } from "../filter/FacetValueTag";
import { RefinementBar } from "../refinementBar/RefinementBar";
import FilterSheet from "../filtersheet/FilterSheet";

const FAVORITES_AUTO_SELECT_TRACKING_LABELS: Partial<TrackingLabels> = {
  san_FacetLocation: "list_initial",
  san_FilterMethod: "automatically_added",
  san_SavedFacetValue: "true",
};

const STORAGE_ID = "heureka_saved_sizes";

const storage = new Storage(window.localStorage);

export default class SizesPersonalization {
  private static initialized: boolean | undefined = false;

  /*               */

  static register() {
    eventQBus.on("heureka.filterSection.loaded", SizesPersonalization.onFilterSectionLoaded);

    /*                                                       */
    eventQBus.on("heureka.filters.loaded", SizesPersonalization.onFiltersReloaded);
    eventQBus.on("heureka.filterPersonalization.saved", SizesPersonalization.onFavoritesSaved);
    eventQBus.on("heureka.refinementBar.loaded", SizesPersonalization.onRefinementBarLoaded);

    /*                     */
    eventQBus.on("heureka.filterPersonalization.save", SizesPersonalization.saveFavorites);
    eventQBus.on("heureka.filterPersonalization.select", SizesPersonalization.selectFavorites);
  }

  static reset() {
    SizesPersonalization.initialized = false;
  }

  private static onFilterSectionLoaded() {
    if (SizesPersonalization.isValidSizePersonalizationPage()) {
      if (SizesPersonalization.initialized !== true) {
        SizesPersonalization.initialized = true;
        SizesPersonalization.initialTracking();
        SizesPersonalization.handlePreviouslySelectedFavorites();
      } else {
        SizesPersonalization.handleAvailableFavorites();
      }
    }
  }

  private static onRefinementBarLoaded() {
    SizesPersonalization.updateFavoriteFacetValueTags();
  }

  private static onFiltersReloaded() {
    SizesPersonalization.updateFavoriteFacetValues(SizesPersonalization.getFavoritesFromLocalStorage());
    SizesPersonalization.updateFavoriteFilterTitles(SizesPersonalization.getFavoritesFromLocalStorage());
  }

  private static onFavoritesSaved(event: FilterPersonalizationSaveEvent) {
    SizesPersonalization.updateFavoriteFacetValues(event.values);
    SizesPersonalization.updateFavoriteFilterTitles(event.values);
  }

  private static selectFavorites(event: FilterPersonalizationSelectEvent) {
    FilterSheet.selectFacetValues(event.select, event.trackingLabels);
  }

  private static saveFavorites(event: FilterPersonalizationSaveEvent) {
    const favoritesFromStorage = SizesPersonalization.loadFromLocalStorage();

    /*                                 */
    for (const favorite of event.values) {
      if (!favoritesFromStorage.has(favorite)) {
        favoritesFromStorage.set(favorite, false);
      }
    }

    SizesPersonalization.saveToLocalStorage(favoritesFromStorage);
    eventQBus.emit("heureka.filterPersonalization.saved", {
      values: Array.from(favoritesFromStorage.keys()),
    });
  }

  static getFavoritesFromLocalStorage(): string[] {
    return Array.from(SizesPersonalization.loadFromLocalStorage().keys());
  }

  private static markSelectedFavoritesInLocalStorage(values: string[]) {
    const favoritesFromStorage = SizesPersonalization.loadFromLocalStorage();
    for (const favorite of favoritesFromStorage.keys()) {
      favoritesFromStorage.set(favorite, values.includes(favorite));
    }
    SizesPersonalization.saveToLocalStorage(favoritesFromStorage);
  }

  private static getSelectedFavoritesFromLocalStorage(): string[] {
    const favoritesFromStorage = SizesPersonalization.loadFromLocalStorage();
    const selectedFavorites: string[] = [];
    favoritesFromStorage.forEach((value: boolean, favorite: string) => {
      if (value) {
        selectedFavorites.push(favorite);
      }
    });
    return selectedFavorites;
  }

  private static loadFromLocalStorage(): Map<string, boolean> {
    try {
      return new Map<string, boolean>(Object.entries(JSON.parse(storage.getItem(STORAGE_ID) || "{}")));
    } catch (e) {
      return new Map<string, boolean>();
    }
  }

  private static saveToLocalStorage(values: Map<string, boolean>) {
    storage.setItem(STORAGE_ID, JSON.stringify(Object.fromEntries(values)));
  }

  /*                       */

  static isValidSizePersonalizationPage() {
    return isMobile() && filterContextId() === "schuhe";
  }

  static isValidSizePersonalizationFilter(filterId: string | undefined) {
    return filterId === "groesse";
  }

  private static handlePreviouslySelectedFavorites() {
    const previouslySelectedFacets = FacetValue.fromIds(SizesPersonalization.getSelectedFavoritesFromLocalStorage());
    const alreadySelectedFavorites = previouslySelectedFacets.filter((f) => f.checked === true);
    const unselectedFavorites = previouslySelectedFacets.filter((f) => f.checked === false);

    if (alreadySelectedFavorites.length == 0 && unselectedFavorites.length > 0) {
      SizesPersonalization.profileTracking(false);
      eventQBus.emit("heureka.filterPersonalization.select", {
        select: unselectedFavorites.flatMap((f) => (f.id ? [f.id] : [])),
        trackingLabels: FAVORITES_AUTO_SELECT_TRACKING_LABELS,
      });
    } else {
      SizesPersonalization.handleAvailableFavorites();
    }
  }

  private static handleAvailableFavorites() {
    const missingPreviouslySelectedFavorites = SizesPersonalization.getSelectedFavoritesFromLocalStorage().filter(
      (f) => FacetValue.fromId(f) === undefined,
    );

    const availableFavorites = FacetValue.fromIds(SizesPersonalization.getFavoritesFromLocalStorage());
    const selectedFavorites = FacetValue.ids(availableFavorites.filter((f) => f.checked === true));
    const unselectedFavorites = FacetValue.ids(availableFavorites.filter((f) => f.checked === false));

    SizesPersonalization.markSelectedFavoritesInLocalStorage(
      selectedFavorites.concat(missingPreviouslySelectedFavorites), /*                                */
    );

    SizesPersonalization.profileTracking(selectedFavorites.length > 0);

    eventQBus.emit("heureka.filterPersonalization.selected", {
      selected: selectedFavorites,
      unselected: unselectedFavorites,
    });
  }

  private static updateFavoriteFacetValueTags() {
    const favorites: string[] = SizesPersonalization.getFavoritesFromLocalStorage();
    const isValidPage = SizesPersonalization.isValidSizePersonalizationPage();

    RefinementBar.forEach((refinementBar) =>
      FacetValueTag.factory.all(refinementBar.refinementForm).forEach((facetValueTag) => {
        const isSavedValue = favorites.includes(<string>facetValueTag.tsFeatureRef);
        facetValueTag.favoriteIconVisibility = isValidPage && isSavedValue;
      }),
    );
  }

  private static updateFavoriteFilterTitles(favorites: string[]) {
    const isValidPage = SizesPersonalization.isValidSizePersonalizationPage();

    Filter.factory
      .all()
      .filter((filter) => SizesPersonalization.isValidSizePersonalizationFilter(filter.id))
      .flatMap((filter) => (filter.filterTitle ? filter.filterTitle.selectedValues : []))
      .forEach((selectedValue) => {
        const isSavedValue = favorites.includes(<string>selectedValue.tsFeatureRef);
        selectedValue.favoriteIconVisibility = isValidPage && isSavedValue;
      });
  }

  private static updateFavoriteFacetValues(favorites: string[]) {
    const isValidPage = SizesPersonalization.isValidSizePersonalizationPage();

    Filter.factory
      .all()
      .filter((filter) => SizesPersonalization.isValidSizePersonalizationFilter(filter.id))
      .flatMap((filter) => filter.facets)
      .flatMap((facet) => facet.allFacetValuesDeprecated)
      .forEach((facetValue) => {
        const isSavedValue = facetValue.id !== undefined && favorites.includes(facetValue.id);

        /*                */
        facetValue.favoriteIconVisibility = isValidPage && isSavedValue;

        /*                    */
        if (facetValue.input) {
          if (isValidPage) {
            updateLabel(facetValue.input, "san_SavedFacetValue", StringHelper.toBooleanString(isSavedValue));
          } else {
            updateLabel(facetValue.input, "san_SavedFacetValue");
          }
        }
      });
  }

  /*       */

  private static initialTracking() {
    eventQBus.emit(
      "tracking.bct.addToPageImpression",
      SizesPersonalization.savedFacetValuesTrackingLabels(SizesPersonalization.getFavoritesFromLocalStorage()),
    );
  }

  private static profileTracking(isActive: boolean) {
    eventQBus.emit("tracking.bct.addToPageImpression", {
      "wk.san_FilterProfileActive": StringHelper.toBooleanString(isActive),
    });
  }

  static savedFacetValuesTrackingLabels(values: string[]): Partial<TrackingLabels> {
    return {
      "wk.san_FilterProfileCreated": StringHelper.toBooleanString(values.length > 0),
      "wk.san_SavedFacetValuesCount": values.length.toString(),
      ...(values.length > 0
        ? {
            "wk.san_SavedFacetValues": values.map((f) => f.split("_").pop() || "").join("|"),
          }
        : {}),
    };
  }
}
