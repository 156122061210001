import "./nextBestAction.scss";
import { Facet } from "../filter/Facet";
import { clear, clone, getElementById, isSearchResultPage } from "../util/Utils";
import { HeurekaElementFactory } from "../util/HeurekaElementFactory";
import { UpdateReferencedAction } from "../filter/UpdateReferencedAction";
import { SubmitOnChangeListener } from "../filter/SubmitOnChange";
import { FacetValueTags } from "../filter/FacetValueTags";
import { PopularFacetValuesWrapper } from "../filter/PopularFacetValues";
import { eventQBus } from "../types/EventQBus";
import { Storage } from "@otto-ec/global-resources/storage";
import { Filter } from "../filter/Filter";
import { updateWhatIfTracking } from "../tracking/WhatIfTracking";
import type { Appendable } from "../util/Appendable";
import { OverflowBox } from "../overflowBox/OverflowBox";
import { filterContextId } from "../filter/FilterContextId";
import { isMobile } from "../util/Breakpoint";
import type { TrackingLabels } from "../tracking/TrackingLabels";
import { getActiveVariation } from "../experiment/Experiments";

const CLASS_NEXT_BEST_ACTIONS = `heureka_nextBestAction`;
const SELECTOR_NEXT_BEST_ACTIONS = `.${CLASS_NEXT_BEST_ACTIONS}`;
const SELECTOR_TEMPLATE_NEXT_BEST_ACTION = `#heureka_nextBestActionTemplate ${SELECTOR_NEXT_BEST_ACTIONS}`;

const CHANGE_TRACKING_LABELS: Partial<TrackingLabels> = {
  san_FacetLocation: "list_initial",
  san_FilterMethod: "popular",
};

const storage = new Storage(window.sessionStorage);

export default class NextBestActionBanner implements Appendable {
  private static readonly factory = HeurekaElementFactory.byClass(CLASS_NEXT_BEST_ACTIONS, NextBestActionBanner);

  private static facetFound = false;
  private static facetId: string | undefined;
  private static filterId: string | undefined;
  private static facetFormId: string | undefined;
  private static facetValuesWrapper: PopularFacetValuesWrapper | undefined;

  /*               */

  constructor(readonly banner: HTMLElement) {}

  /*                  */

  static fromTemplate(rootElement?: ParentNode | null): NextBestActionBanner | undefined {
    return NextBestActionBanner.factory.pick(SELECTOR_TEMPLATE_NEXT_BEST_ACTION, rootElement);
  }

  /*               */

  static register() {
    eventQBus.on("heureka.filterSection.loaded", NextBestActionBanner.initActionsFacet);

    window.o_global.breakpoint.registerChangeListener(NextBestActionBanner.onBreakpointChange);

    /*                                                */
    eventQBus.once("ftfind.filterSheet.open", NextBestActionBanner.disableOnCurrentPage);

    /*                                           */
    eventQBus.once("heureka.filterSection.loaded", NextBestActionBanner.onFilterSectionLoaded);
  }

  static registerStatusQuo() {
    eventQBus.on("heureka.filterSection.loaded", NextBestActionBanner.initActionsFacet);
  }

  private init() {
    clear(this.banner);

    const originalValuesWrapper: PopularFacetValuesWrapper | undefined = NextBestActionBanner.facetValuesWrapper;
    const originalFormId: string | undefined = NextBestActionBanner.facetFormId;

    if (originalValuesWrapper && originalFormId) {
      const valuesWrapper: PopularFacetValuesWrapper = originalValuesWrapper.clone();
      this.prepareValues(valuesWrapper, originalFormId);
      this.addValues(valuesWrapper);

      this.banner.addEventListener("oc-close", NextBestActionBanner.disableOnCurrentPage, { passive: true });

      this.visible = true;
    } else {
      this.visible = false;
    }

    return this;
  }

  private addValues(popularValuesWrapper: PopularFacetValuesWrapper) {
    this.banner.appendChild(document.createElement("form")).appendChild(popularValuesWrapper.popularFacetValuesWrapper);
  }

  private prepareValues(valuesWrapper: PopularFacetValuesWrapper, formId: string) {
    valuesWrapper.hidden = false;
    valuesWrapper.addChangeTrackingLabels(CHANGE_TRACKING_LABELS);
    valuesWrapper.toggleChipsContainer(true, true);
    valuesWrapper.enableVisibleValues();

    /*                                                                     */
    PopularFacetValuesWrapper.factory.release(valuesWrapper.popularFacetValuesWrapper);
    UpdateReferencedAction.on(valuesWrapper.popularFacetValuesWrapper);
    SubmitOnChangeListener.onForForm(valuesWrapper.popularFacetValuesWrapper, formId, this.onNextBestActionClicked);
  }

  private onNextBestActionClicked() {
    if (NextBestActionBanner.filterId) {
      Filter.updateChangeTrackingLabels(NextBestActionBanner.filterId, CHANGE_TRACKING_LABELS);
    }
  }

  private static onFilterSectionLoaded() {
    /*                                                           */
    if (NextBestActionBanner.possibleBestActions.length > 0 && !NextBestActionBanner.mayVisible) {
      NextBestActionBanner.disableOnCurrentPage();
    }
  }

  private static onBreakpointChange() {
    eventQBus.emit("heureka.nextBestAction.loaded");
  }

  private static disableOnCurrentPage() {
    const pageIdentifier: string | undefined = NextBestActionBanner.returnPageIdentifier();
    if (pageIdentifier) {
      storage.setItem(pageIdentifier, "true");
    }
    NextBestActionBanner.facet = undefined;

    /*                */
    eventQBus.emit("heureka.nextBestAction.loaded");
  }

  private static isCurrentPageAlreadyDisabled(): boolean {
    const pageIdentifier: string | undefined = NextBestActionBanner.returnPageIdentifier();
    return pageIdentifier ? storage.getItem(pageIdentifier) === "true" : false;
  }

  private static returnPageIdentifier(): string | undefined {
    const searchTerm: string | undefined = getElementById("reptile-searchterm")?.dataset.searchterm;
    return searchTerm ? "heureka_nba_" + searchTerm : undefined;
  }

  /*                       */

  appendTo(elem: HTMLElement): void {
    elem.appendChild(this.banner);
    this.recomputeSize();
  }

  clone() {
    return new NextBestActionBanner(clone(this.banner)).init();
  }

  get visible(): boolean {
    return !this.banner.hidden && this.banner.childElementCount > 0;
  }

  set visible(visibility: boolean) {
    this.banner.hidden = !visibility;
  }

  static get selectedFacet(): string | undefined {
    return NextBestActionBanner.facetId;
  }

  static get possibleBestActions(): string[] {
    return (NextBestActionBanner.facetValuesWrapper?.visibleFacetValues || [])
      .map((f) => f.featureRef)
      .flatMap((f) => (f ? [f] : []));
  }

  static get mayVisible(): boolean {
    if (!NextBestActionBanner.isValidPage()) {
      return false;
    }
    const nextBestActionValues: string[] = NextBestActionBanner.possibleBestActions;
    if (nextBestActionValues.length > 0) {
      const actualSelectedValues = (FacetValueTags.template()?.facetValueTags || [])
        .map((f) => f.tsFeatureRef)
        .flatMap((f) => (f ? [f] : []));

      /*                                           */
      return !actualSelectedValues.some((f) => nextBestActionValues.indexOf(f) < 0);
    }

    return false;
  }

  static set facet(facet: Facet | undefined) {
    if (facet === undefined && !NextBestActionBanner.facetFound) {
      return;
    }

    NextBestActionBanner.facetValuesWrapper = facet?.popularFacetValuesWrapper;
    NextBestActionBanner.facetFormId = facet?.filter?.formId;
    NextBestActionBanner.facetId = facet?.id;
    NextBestActionBanner.filterId = facet?.filter?.id;
    NextBestActionBanner.facetFound = facet !== undefined;
  }

  private static isValidPage(): boolean {
    return NextBestActionBanner.isPossibleNextBestActionPage() && !NextBestActionBanner.isCurrentPageAlreadyDisabled();
  }

  private static isPossibleNextBestActionPage() {
    return filterContextId() === "mode" && isMobile() && isSearchResultPage();
  }

  private static initActionsFacet() {
    NextBestActionBanner.updateActionsFacet();
    NextBestActionBanner.tracking();
  }

  private static updateActionsFacet() {
    if (NextBestActionBanner.isValidPage()) {
      NextBestActionBanner.facet = Filter.factory
        .all()
        .find((filter) => !filter.hidden && filter.mayNextBestActionFacet)?.nextBestActionFacet;
    } else {
      NextBestActionBanner.facet = undefined;
    }
  }

  /*       */

  private static tracking() {
    if (NextBestActionBanner.isPossibleNextBestActionPage()) {
      updateWhatIfTracking(`e3049_${NextBestActionBanner.mayVisible}`);
      if (getActiveVariation("e3049", "StatusQuo") !== "StatusQuo") {
        NextBestActionBanner.trackSanTopQuickFacet();
      }
    }
  }

  private static trackSanTopQuickFacet() {
    eventQBus.emit("tracking.bct.addToPageImpression", {
      san_TopQuickFacet: NextBestActionBanner.facetId || "none",
    });
  }

  private recomputeSize() {
    OverflowBox.factory.pick("ul", this.banner)?.initializeCollapsedState(true);
  }
}
