import { HeurekaElementFactory } from "../util/HeurekaElementFactory";
import { FacetGroupSelect } from "./FacetGroupSelect";
import { Facet } from "./Facet";
import { all } from "../util/Reductions";
import { FilterAccordion } from "./FilterAccordion";
import { FacetGroup } from "./FacetGroup";
import { readLabel, updateLabel, updatePosition, updateStatus } from "../tracking/FeatureTracking";
import type { FeatureStatus } from "../tracking/Tracking";
import type { TrackingLabels } from "../tracking/TrackingLabels";
import { FilterTitle } from "./FilterTitle";
import type { FacetVisibilityAware } from "./FacetVisibilityAware";
import { eventQBus } from "../types/EventQBus";
import type { FilterSectionLoadedEvent } from "../multifiltering/FilterTypes";
import { UpdateReferencedAction } from "./UpdateReferencedAction";
import { persistPreSubmitTrackingLabels, setChangeLabels } from "./FilterTracking";
import { FilterRelevancy } from "./FilterRelevancy";
import { FilterlistExpander } from "../filterlistExpander/FilterlistExpander";

const FILTER_CLASS = "find_filter";
const TRACKABLE_CLASS = "ts_heureka_trackable";

export class Filter implements FacetVisibilityAware {
  static readonly factory = HeurekaElementFactory.byElement("form", FILTER_CLASS, Filter);

  /*               */
  constructor(readonly form: HTMLFormElement) {}

  /*                  */

  static filterId(id: string, root?: NonElementParentNode) {
    return Filter.factory.byId(`find_filter_${id}`, root);
  }

  /*               */
  static register() {
    eventQBus.on("heureka.filters.loaded", Filter.initAll);
    eventQBus.on("heureka.filterVisibilities.loaded", Filter.onFilterVisibilitiesLoaded);
  }

  static initAll(event: FilterSectionLoadedEvent, rootElement?: ParentNode) {
    Filter.factory.forEach((filter) => filter.init(), rootElement);
  }

  static prepareAll(fragment: ParentNode, initialLoad = false) {
    Filter.factory.forEach((filter) => filter.#prepare(initialLoad, fragment), fragment);
  }

  #prepare(initialLoad: boolean, fragment: ParentNode) {
    const isDenseMode = !FilterlistExpander.desktopExpander()?.expanded;
    this.isRelevant = FilterRelevancy.template(fragment)?.isRelevant(this.id) || this.isRelevant;
    this.isIrrelevant = FilterRelevancy.template(fragment)?.isIrrelevant(this.id) || this.isIrrelevant;
    this.filterVisibility = this.isDenseModeFilter;
    if (this.filterVisibility !== "dense" && isDenseMode && !this.facets.some((facet) => facet.selected)) {
      if (initialLoad) {
        this.featureStatus = "hidden";
        this.facets.forEach((facet) => {
          facet.featureStatus = "hidden";
        });
      } else {
        this.featureStatus = this.hiddenStatus();
        this.facets.forEach((facet) => {
          facet.featureStatus = facet.hiddenStatus();
        });
      }
    }
  }

  static onFilterVisibilitiesLoaded() {
    Filter.factory.forEach((filter) => filter.updateFilterTitleInlineMode());
  }

  static handleError() {
    Filter.factory.forEach((filter: Filter) => {
      const isReset = filter.resetToPreSubmitState();
      if (isReset) {
        filter.showError();
        filter.filterTitle?.invalidate();
      }
    });
  }

  protected init() {
    UpdateReferencedAction.on(this.form);
    return this;
  }

  /*                       */

  get id(): string {
    return this.form.id.substring("find_filter_".length);
  }

  get formId(): string {
    return this.form.id;
  }

  get facets(): Facet[] {
    return Facet.factory.all(this.form);
  }

  get facetGroups(): FacetGroup[] {
    return FacetGroup.factory.all(this.form);
  }

  get facetGroupSelect(): FacetGroupSelect | undefined {
    return FacetGroupSelect.factory.pick(undefined, this.form);
  }

  get filterAccordion() {
    return FilterAccordion.filterId(this.id);
  }

  get filterTitle() {
    return FilterTitle.filterId(this.id);
  }

  get wrappers(): (FacetVisibilityAware | undefined)[] {
    return [this.filterAccordion, this.filterTitle];
  }

  get mayHide() {
    return this.facets.map((facet) => facet.hidden).reduce(all);
  }

  get hidden(): boolean {
    return this.form.hidden;
  }

  set hidden(hidden) {
    this.form.hidden = hidden;
    if (hidden) {
      this.featureStatus = this.hiddenStatus();
    }
  }

  get filterMessage(): HTMLElement | null {
    return this.form.querySelector(".find_filterMessage") || null;
  }

  get mayNextBestActionFacet(): boolean {
    return !!this.nextBestActionFacet;
  }

  get nextBestActionFacet(): Facet | undefined {
    const facets: Facet[] = this.activeFacets;
    return facets.find((facet) => facet.mayNextBestAction);
  }

  get activeFacets() {
    return (
      this.facetGroups.find((facetGroup: FacetGroup) => {
        return facetGroup.active;
      })?.facets || this.facets
    );
  }

  get hasVisiblePopularValues() {
    return this.facets.some((facet) => facet.popularFacetValuesWrapper?.hasVisibleValues());
  }

  get trackable(): boolean {
    return this.form.classList.contains(TRACKABLE_CLASS);
  }

  set featureStatus(status: FeatureStatus | undefined) {
    updateStatus(this.form, status);
  }

  set position(position: number) {
    updatePosition(this.form, position);
  }

  set filterFoldoutStatus(value: TrackingLabels["san_FilterFoldoutStatus"]) {
    updateLabel(this.form, "san_FilterFoldoutStatus", this.hidden ? "hidden" : value);
  }

  get filterVisibility(): TrackingLabels["san_FilterVisibility"] | undefined {
    return readLabel(this.form, "san_FilterVisibility");
  }

  set filterVisibility(value: boolean | TrackingLabels["san_FilterVisibility"]) {
    if (typeof value !== "boolean") {
      updateLabel(this.form, "san_FilterVisibility", value);
    } else {
      updateLabel(this.form, "san_FilterVisibility", value ? "dense" : "expanded");
    }
  }

  set isRelevant(value: boolean) {
    this.form.dataset.isRelevant = value.toString();
  }

  get isRelevant() {
    return this.form.dataset.isRelevant === "true";
  }

  set isIrrelevant(value: boolean) {
    this.form.dataset.isIrrelevant = value.toString();
  }

  get isIrrelevant() {
    return this.form.dataset.isIrrelevant === "true";
  }

  hiddenStatus(): FeatureStatus | undefined {
    return this.id === "kategorie" || this.id === "kategorien" ? "hidden" : undefined;
  }

  public static updateChangeTrackingLabels(filterId: string, values: Partial<TrackingLabels> | undefined) {
    const filter = Filter.filterId(filterId);
    if (filter) {
      setChangeLabels(filter.form, values);
    }
  }

  clearPosition() {
    this.position = 0;
    return this;
  }

  updateVisibility(shouldHide: boolean) {
    this.hidden = this.mayHide && shouldHide;
    this.wrappers.forEach((wrapper) => wrapper?.updateVisibility(this.hidden));
  }

  private updateFilterTitleInlineMode() {
    this.filterTitle?.toggleInlineMode(this.hasVisiblePopularValues);
  }

  /*           */
  private resetToPreSubmitState(): number {
    return persistPreSubmitTrackingLabels(this.form);
  }

  private showError() {
    const msg = this.filterMessage;
    if (msg) {
      msg.dataset.messageText = "Keine Treffer gefunden.";
    }
  }

  get isVisibleInDenseMode(): boolean {
    return this.isRelevant || this.facets.some((f) => !f.mayHiddenByFilterRelevancy);
  }

  get isDenseModeFilter(): boolean {
    return this.isRelevant || this.facets.some((f) => f.ignoreMinimalCoverage);
  }
}
