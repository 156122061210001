import type { EventMergeId } from "./Tracking";
import type { TrackingLabels } from "./TrackingLabels";
import { eventQBus } from "../types/EventQBus";
import { misc } from "@otto-ec/global-resources/misc";

let eventMergeId: EventMergeId | undefined;

export function createEventMergeId() {
  if (eventMergeId) {
    console.warn("EventMergeId already defined");
  }
  eventMergeId = misc.guid();
  return eventMergeId;
}

export function deleteEventMergeId(): EventMergeId | undefined {
  const tmp = eventMergeId;
  eventMergeId = undefined;
  return tmp;
}

export function ensureEventMergeId(): EventMergeId {
  return eventMergeId || createEventMergeId();
}

export function submitEvent(tracking: Partial<TrackingLabels>, eventMergeId?: string) {
  if (eventMergeId) {
    eventQBus.emit("tracking.bct.addToEvent", tracking, eventMergeId);
  } else {
    eventQBus.emit("tracking.bct.submitEvent", tracking);
  }
}
