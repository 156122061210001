import type { FilterSectionInitialLoadedEvent, FilterSectionLoadEvent, Page } from "./FilterTypes";
import { eventQBus } from "../types/EventQBus";
import { filterContextId } from "../filter/FilterContextId";
import { FilterSectionWrapper } from "../filter/FilterSectionWrapper";
import type { Reloadable } from "../util/Reloadable";
import { Filter } from "../filter/Filter";
import { addSanErrorToPageImpression } from "../filter/FilterTracking";

export class LoadFilter {
  private static readonly FILTERS_URI: string = "/leafcutter/filters";

  static async onLoadFilters({ rule, page, ssid }: FilterSectionLoadEvent): Promise<void> {
    try {
      const node: FilterSectionWrapper = await FilterSectionWrapper.template();
      const response: Response = await fetch(LoadFilter.uri(rule, page, filterContextId()), {
        headers: LoadFilter.requestHeaders(ssid),
      });
      const html: string = await LoadFilter.extractResponseText(response);
      return LoadFilter.replaceNodeContents(node, rule, html);
    } catch (e) {
      return LoadFilter.handleErrorReload(e as Error);
    }
  }

  static async onInitialLoadedFilters({ response, rule }: FilterSectionInitialLoadedEvent) {
    try {
      const node: FilterSectionWrapper = await FilterSectionWrapper.template();
      const html: string = await LoadFilter.extractResponseText(response);
      return LoadFilter.replaceNodeContents(node, rule, html, true);
    } catch (e) {
      return LoadFilter.handleErrorInitialLoad(e as Error);
    }
  }

  private static async extractResponseText(resp: Response) {
    if (resp.status !== 200) {
      throw Error(`Failed to retrieve filtersection: ${resp.status} ${resp.statusText}`);
    }
    return resp.text();
  }

  /**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
  private static replaceNodeContents(reloadable: Reloadable, rule: string, html: string, initialLoad = false): void {
    /*                                 */
    const range = document.createRange();
    range.selectNodeContents(reloadable.elem);

    /*                                                               */
    const fragment = range.createContextualFragment(html);

    /*                               */
    reloadable.prepare(fragment, initialLoad);

    /*           */
    range.deleteContents();
    range.insertNode(fragment);

    /*                     */
    reloadable.elem.dataset.rule = rule;

    /*                                                                       */
    window.invokePreload?.processPreloadsOnElement(reloadable.elem);
  }

  private static handleErrorReload(e: Error): void {
    console.warn(`Filter reload encountered an error: ${e.message}`);
    Filter.handleError();
    addSanErrorToPageImpression("filterlist_notloaded");
    eventQBus.emit("heureka.filters.loadAborted");
  }

  private static handleErrorInitialLoad(e: Error): void {
    console.warn(`Filter reload encountered an error: ${e.message}`);
    addSanErrorToPageImpression("filterlistInitial_notloaded");
    eventQBus.emit("heureka.filterSection.loadAborted");
  }

  private static uri(dataRule: string, page: Page, filterContext: string) {
    let url = `${LoadFilter.FILTERS_URI}?rule=${encodeURIComponent(dataRule)}&fc=${filterContext}`;
    if (page.sortiertnach) {
      url = `${url}&sortiertnach=${encodeURIComponent(page.sortiertnach)}`;
    }
    return url;
  }

  private static requestHeaders(ssid?: string): HeadersInit | undefined {
    return ssid ? { "X-Heureka-SSID": ssid } : undefined;
  }
}
