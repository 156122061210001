import { HeurekaElementFactory } from "../util/HeurekaElementFactory";
import { eventQBus } from "../types/EventQBus";
import { ShowMore } from "./ShowMore";

const SHOW_MORE_LINK_CLASS = "heureka_showMore__button";

export class ShowMoreLink {
  static readonly factory = HeurekaElementFactory.byClass(SHOW_MORE_LINK_CLASS, ShowMoreLink);

  constructor(readonly showMoreLink: HTMLElement) {}

  public prepare() {
    this.registerLinkClickHandler();
  }

  private registerLinkClickHandler() {
    this.showMoreLink.addEventListener("click", ShowMoreLink.handleClickEvent);
  }

  private static handleClickEvent(event: Event) {
    event.preventDefault();
    const showMore: ShowMore | undefined = ShowMore.factory.closest(event.currentTarget);
    if (showMore) {
      const expanded = showMore.toggle();
      eventQBus.emit("tracking.bct.submitEvent", { san_FacetActivity: expanded ? "show_more" : "show_less" });
    }
  }

  public set hidden(hidden: boolean) {
    this.showMoreLink.hidden = hidden;
  }
}
