const FILTER_CONTEXT_ID = "heureka_filterContextId";

export default class FilterContextId {
  /*               */

  private constructor(public readonly id: string) {}

  /*                  */

  static create(id: string) {
    return new FilterContextId(id);
  }

  static declare(node: Document | DocumentFragment = document): FilterContextId {
    const id = node.getElementById(FILTER_CONTEXT_ID)?.dataset.contextId || "default";
    return this.create(id);
  }

  /*                       */

  isDefault() {
    return this.id === "default";
  }

  getNonDefaultId(): string | undefined {
    return !this.isDefault() ? this.id : undefined;
  }

  getId(): string {
    return this.id;
  }
}

export function filterContextId(node: Document | DocumentFragment = document) {
  return FilterContextId.declare(node).getId();
}
