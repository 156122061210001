import { eventQBus } from "../types/EventQBus";
import type { DresonRuleResolveAbortedEvent, DresonRuleResolvedEvent } from "./FilterTypes";
import { LoadFilter } from "./LoadFilter";
import {
  moveInitialFacetVisibilities,
  onFilterVisibilitiesLoad,
  updateFilterVisibilitiesFromPersisted,
} from "./LoadFilterVisibilities";
import { TsBct } from "./TsBct";
import { addFeaturesToPageImpression } from "../tracking/FeatureTracking";
import { Filter } from "../filter/Filter";
import { addSanErrorToPageImpression, FILTER_FEATURES } from "../filter/FilterTracking";
import { isActive } from "../toggle/Toggle";
import { isSearchResultPage } from "../util/Utils";
import { getActiveVariation } from "../experiment/Experiments";

export function registerFilterReloading() {
  eventQBus.on("ftfind.dresonRule.resolved", (data: DresonRuleResolvedEvent) => {
    /*                                                          */
    if (data && data.mergeOnPi) {
      if (data.count === 0) {
        Filter.handleError();
        addSanErrorToPageImpression("filterlist_empty");
        addFeaturesToPageImpression(FILTER_FEATURES);
      } else {
        eventQBus.emit("heureka.filterSection.load", { ...data, ssid: TsBct.sfid() });
      }
    }
  });

  eventQBus.on("ftfind.dresonRule.resolveAborted", (data: DresonRuleResolveAbortedEvent) => {
    Filter.handleError();
    /*                                             */
    if ((data && data.mergeOnPi) || isActive("HEUREKA_1227_IGNORE_MERGEONPI_ATTR_ON_LOAD_FAILURE")) {
      addSanErrorToPageImpression("unknown");
      addFeaturesToPageImpression(FILTER_FEATURES);
    }
  });

  eventQBus.on("heureka.filterSection.load", (event) => {
    const filtersLoadPromise = LoadFilter.onLoadFilters(event);
    if (getActiveVariation("e3213", "StatusQuo") !== "StatusQuo" && isSearchResultPage()) {
      filtersLoadPromise.then(() => {
        eventQBus.emit("heureka.filterSection.loaded");
      });
    } else {
      const filterVisibilitiesLoadPromise = onFilterVisibilitiesLoad(event);
      Promise.all([filtersLoadPromise, filterVisibilitiesLoadPromise]).then(() => {
        updateFilterVisibilitiesFromPersisted();
        eventQBus.emit("heureka.filterSection.loaded");
      });
    }
  });
  eventQBus.once("heureka.filters.loaded", moveInitialFacetVisibilities);

  eventQBus.on("heureka.filterSection.initialLoaded", LoadFilter.onInitialLoadedFilters);
}
