import type { Appendable } from "../util/Appendable";
import { HeurekaElementFactory } from "../util/HeurekaElementFactory";
import { clone } from "../util/Utils";
import { eventQBus } from "@otto-ec/global-resources/event-q-bus";
import type { FilterPersonalizationSelectedEvent } from "../multifiltering/FilterTypes";
import { isMobile } from "../util/Breakpoint";
import { FacetValue } from "../filter/FacetValue";
import type { TrackingLabels } from "../tracking/TrackingLabels";

const TEMPLATE_ID = `heureka_personalizationBannerTemplate`;
const SELECTOR_TEMPLATE = `#${TEMPLATE_ID}`;
const CLASS_BANNER = `heureka_personalizationBanner`;
const SELECTOR_BANNER = `.${CLASS_BANNER}`;
const SELECTOR_TEMPLATE_BANNER = `${SELECTOR_TEMPLATE} ${SELECTOR_BANNER}`;
const SELECTOR_DENY_ACTION = `${SELECTOR_BANNER}__deny`;
const SELECTOR_APPLY_ACTION = `${SELECTOR_BANNER}__apply`;

const FAVORITES_BANNER_SELECT_TRACKING_LABELS: Partial<TrackingLabels> = {
  san_FacetLocation: "list_initial",
  san_FilterMethod: "saved_facet_value",
  san_SavedFacetValue: "true",
};

export default class SizesPersonalizationBanner implements Appendable {
  static readonly factory = HeurekaElementFactory.byClass(CLASS_BANNER, SizesPersonalizationBanner);

  private static selectableFacetValueIds: string[] = [];
  private static initialized: boolean | undefined = false;
  private static disabledOnCurrentPage: boolean | undefined = false;
  private facetValues: FacetValue[] = [];

  /*               */

  constructor(readonly banner: HTMLElement) {}

  /*                  */

  static fromTemplate(rootElement?: ParentNode | null): SizesPersonalizationBanner | undefined {
    return SizesPersonalizationBanner.factory.pick(SELECTOR_TEMPLATE_BANNER, rootElement);
  }

  /*               */

  static register() {
    eventQBus.on("heureka.filterPersonalization.selected", SizesPersonalizationBanner.onFavoritesSelected);

    window.o_global.breakpoint.registerChangeListener(SizesPersonalizationBanner.fireBannerUpdate);
  }

  static reset() {
    SizesPersonalizationBanner.initialized = false;
    SizesPersonalizationBanner.disabledOnCurrentPage = false;
  }

  private static fireBannerUpdate() {
    eventQBus.emit("heureka.filterPersonalizationBanner.loaded");
  }

  private init() {
    this.facetValues = FacetValue.fromIds(SizesPersonalizationBanner.selectableFacetValueIds);

    if (this.facetValues.length > 0) {
      this.headline = this.facetValues.length === 1 ? `Deine gemerkte Schuhgröße` : `Deine gemerkten Schuhgrößen`;
      const facetValuesStrings = this.facetValues.flatMap((f) => (f.spanInnerHTML ? [f.spanInnerHTML] : []));
      this.body =
        this.facetValues.length === 1
          ? `Du hast die Schuhgröße ${facetValuesStrings[0]} ausgewählt. Möchtest du sie jetzt anwenden?`
          : `Du hast die Schuhgrößen ${facetValuesStrings.slice(0, -1).join(", ")} & ${facetValuesStrings.slice(-1)} ausgewählt. Möchtest du sie jetzt anwenden?`;

      this.banner.addEventListener("oc-close", () => this.onCloseClick());
      this.denyAction?.addEventListener("click", () => this.onDenyClick());
      this.acceptAction?.addEventListener("click", () => this.onAcceptClick());

      this.visible = true;
    } else {
      this.visible = false; /*                   */
    }

    return this;
  }

  private static onFavoritesSelected(event: FilterPersonalizationSelectedEvent) {
    if (SizesPersonalizationBanner.initialized !== true) {
      SizesPersonalizationBanner.initialized = true;
      SizesPersonalizationBanner.selectableFacetValueIds = event.selected.length === 0 ? event.unselected : [];
    } else if (event.selected.length > 0) {
      SizesPersonalizationBanner.selectableFacetValueIds = [];
      SizesPersonalizationBanner.disabledOnCurrentPage = true; /*                                                   */
    }

    /*                   */
    SizesPersonalizationBanner.fireBannerUpdate();
  }

  private onCloseClick() {
    this.visible = false;
    this.activityTracking("click_on_X");
    SizesPersonalizationBanner.disabledOnCurrentPage = true;
  }

  private onDenyClick() {
    this.visible = false;
    this.activityTracking("dont_apply_facetvalues");
    SizesPersonalizationBanner.disabledOnCurrentPage = true;
  }

  private onAcceptClick() {
    this.visible = false;
    this.activityTracking("apply_facetvalues");
    SizesPersonalizationBanner.disabledOnCurrentPage = true;
    eventQBus.emit("heureka.filterPersonalization.select", {
      select: SizesPersonalizationBanner.selectableFacetValueIds,
      trackingLabels: FAVORITES_BANNER_SELECT_TRACKING_LABELS,
    });
  }

  /*                       */

  appendTo(elem: HTMLElement): void {
    elem.appendChild(this.banner);
  }

  clone() {
    return new SizesPersonalizationBanner(clone(this.banner)).init();
  }

  get visible(): boolean {
    return !this.banner.hidden && this.banner.childElementCount > 0;
  }

  set visible(visibility: boolean) {
    this.banner.hidden = !visibility;
  }

  get headline(): string {
    return this.banner.getAttribute("headline") || "";
  }

  set headline(headline: string) {
    this.banner.setAttribute("headline", headline);
  }

  get body(): string {
    return this.banner.textContent || "";
  }

  set body(body: string) {
    this.banner.insertAdjacentText("afterbegin", body);
  }

  get denyAction(): HTMLAnchorElement | null {
    return this.banner.querySelector(SELECTOR_DENY_ACTION);
  }

  get acceptAction(): HTMLAnchorElement | null {
    return this.banner.querySelector(SELECTOR_APPLY_ACTION);
  }

  static get facetValueIds(): string[] {
    return this.selectableFacetValueIds;
  }

  static get mayVisible(): boolean {
    return (
      isMobile() &&
      SizesPersonalizationBanner.disabledOnCurrentPage !== true &&
      SizesPersonalizationBanner.selectableFacetValueIds.length > 0
    );
  }

  /*       */

  private activityTracking(activity: TrackingLabels["san_FacetActivity"]) {
    eventQBus.emit("tracking.bct.submitEvent", {
      san_FacetActivity: activity,
      san_FacetActivityType: Array.from(new Set(this.facetValues.map((facetValue) => facetValue.name))).join("|"),
    });
  }
}
