import "./sizesPersonalization.scss";
import { HeurekaElementFactory } from "../util/HeurekaElementFactory";
import { eventQBus } from "../types/EventQBus";
import { clone } from "../util/Utils";
import type { OnFilterSheetOpenDetail } from "../filtersheet/FilterSheetTopics";
import { Filter } from "../filter/Filter";
import type { Appendable } from "../util/Appendable";
import { getActiveSheet } from "../util/NonModuleFunctions";
import { FacetValue } from "../filter/FacetValue";
import type { TrackingLabels } from "../tracking/TrackingLabels";
import { Facet } from "../filter/Facet";
import type { FilterPersonalizationSaveEvent } from "../multifiltering/FilterTypes";
import FilterSheet from "../filtersheet/FilterSheet";
import SizesPersonalization from "./SizesPersonalization";

const TEMPLATE_ID = `heureka_personalizationFilterSheetTemplate`;
const SELECTOR_TEMPLATE = `#${TEMPLATE_ID}`;
const CLASS_BANNER = `heureka_personalizationFilterSheet`;
const SELECTOR_BANNER = `.${CLASS_BANNER}`;
const SELECTOR_TEMPLATE_BANNER = `${SELECTOR_TEMPLATE} ${SELECTOR_BANNER}`;
const SELECTOR_DENY_SAVE_LINK = `${SELECTOR_BANNER}__denySave`;
const SELECTOR_ACCEPT_SAVE_LINK = `${SELECTOR_BANNER}__acceptSave`;

export default class SizesPersonalizationFilterSheet implements Appendable {
  static readonly factory = HeurekaElementFactory.byClass(CLASS_BANNER, SizesPersonalizationFilterSheet);

  /*                                                                   */
  private bannerResizeObserver: ResizeObserver | undefined = undefined;
  private bannerPrecedingFacetValueId: string | undefined = undefined;

  /*               */
  constructor(readonly banner: HTMLElement) {}

  /*                  */

  static fromTemplate(rootElement?: ParentNode | null): SizesPersonalizationFilterSheet | undefined {
    return SizesPersonalizationFilterSheet.factory.pick(SELECTOR_TEMPLATE_BANNER, rootElement);
  }

  static fromActiveSheet(): SizesPersonalizationFilterSheet | undefined {
    const sheetContent = getActiveSheet()?.getContent();
    return SizesPersonalizationFilterSheet.factory.pick(undefined, sheetContent);
  }

  /*               */

  static register() {
    /*                     */
    eventQBus.on("heureka.filterPersonalization.saved", SizesPersonalizationFilterSheet.onFavoritesSaved);

    /*                                                             */
    eventQBus.on("ftfind.filterSheet.openedDetail", SizesPersonalizationFilterSheet.onFilterSheetDetailOpened);
  }

  private static onFavoritesSaved(event: FilterPersonalizationSaveEvent) {
    const sizePersonalization = SizesPersonalizationFilterSheet.fromActiveSheet();
    /*                                                                         */
    if (sizePersonalization) {
      SizesPersonalizationFilterSheet.updateFilterSheetTracking(event.values);
    }
  }

  private static onFilterSheetDetailOpened(event: OnFilterSheetOpenDetail) {
    if (
      SizesPersonalization.isValidSizePersonalizationPage() &&
      SizesPersonalization.isValidSizePersonalizationFilter(event.filterId)
    ) {
      const sizePersonalization = SizesPersonalizationFilterSheet.fromTemplate()?.clone();
      const sheetContent = getActiveSheet()?.getContent();
      if (sizePersonalization && sheetContent) {
        sizePersonalization.registerFilterFormListener(event.filterId);
        sizePersonalization.registerSaveListener();
        sizePersonalization.appendTo(sheetContent);

        /*                                     */
        SizesPersonalizationFilterSheet.updateFilterSheetTracking(SizesPersonalization.getFavoritesFromLocalStorage());
      }
    }
  }

  /*                       */

  appendTo(target: HTMLElement): void {
    target.appendChild(this.banner);
  }

  clone() {
    return new SizesPersonalizationFilterSheet(clone(this.banner));
  }

  get facetValues(): Map<string, string> {
    const values = this.banner.dataset.values;
    return values ? new Map(JSON.parse(values)) : new Map();
  }

  set facetValues(values: Map<string, string>) {
    this.banner.dataset.values = JSON.stringify(Array.from(values));
  }

  get facetId(): string | undefined {
    return this.banner.dataset.facetId;
  }

  set facetId(facetId: string | undefined) {
    this.banner.dataset.facetId = facetId;
  }

  get valueIds(): string[] {
    return Array.from(this.facetValues.keys());
  }

  get valueTitles(): string[] {
    return Array.from(this.facetValues.values());
  }

  private emptyBanner() {
    this.facetValues = new Map<string, string>();
  }

  private addToBanner(filterTitle: string, facetValue: FacetValue) {
    if (facetValue.id) {
      const valuesMap: Map<string, string> = this.facetValues;
      if (facetValue.spanInnerHTML) {
        valuesMap.set(facetValue.id, facetValue.spanInnerHTML);
      }
      this.facetValues = valuesMap;
      this.updateBannerHeadline(filterTitle);
    }
  }

  private removeFromBanner(title: string, facetValue: FacetValue) {
    if (facetValue.id) {
      const facetValuesMap = this.facetValues;
      facetValuesMap.delete(facetValue.id);
      this.facetValues = facetValuesMap;
      this.updateBannerHeadline(title);
    }
  }

  private updateBannerHeadline(filterTitle: string): void {
    const values = SizesPersonalizationFilterSheet.sortValues(this.valueTitles);
    if (values.length > 0) {
      this.headline =
        values.length === 1
          ? `${filterTitle} ${this.valueTitles[0]} merken`
          : `${filterTitle} ${values.slice(0, -1).join(", ")} & ${values.slice(-1)} merken`;
    }
  }

  get denySaveLink(): HTMLAnchorElement | null {
    return this.banner.querySelector(SELECTOR_DENY_SAVE_LINK);
  }

  get acceptSaveLink(): HTMLAnchorElement | null {
    return this.banner.querySelector(SELECTOR_ACCEPT_SAVE_LINK);
  }

  get headline(): string {
    return this.banner.getAttribute("headline") || "";
  }

  set headline(headline: string) {
    this.banner.setAttribute("headline", headline);
  }

  private registerFilterFormListener(filterId: string) {
    const filter: Filter | undefined = Filter.filterId(filterId);
    if (filter) {
      filter.form.addEventListener("change", (event) =>
        this.onFilterFormChange(event, filter.filterTitle?.filterTitle)
      );
      filter.facetGroupSelect?.select.addEventListener("change", () => this.onFacetGroupChange());
    }
  }

  private onFacetGroupChange() {
    /*                              */
    this.hideBanner();
    this.emptyBanner();
  }

  private onFilterFormChange(event: Event, filterTitle: string | undefined) {
    const target = event.target as HTMLElement;
    if (target instanceof HTMLInputElement && target.closest("fieldset")) {
      /*                                                                   */
      if (target.closest(".pl_sheet__content[data-filter-id]")) {
        this.facetId = Facet.factory.declare(target.closest("fieldset"))?.id;
        const facetValue = FacetValue.factory.declare(target.closest("label"));
        if (
          facetValue &&
          facetValue.id &&
          !SizesPersonalization.getFavoritesFromLocalStorage().includes(facetValue.id)
        ) {
          if (facetValue.checked) {
            this.addToBanner(filterTitle || "", facetValue);
            if (this.facetValues.size > 0) {
              this.registerFacetValueResizeObserver(facetValue);
              this.showBanner();
            }
          } else {
            this.removeFromBanner(filterTitle || "", facetValue);
            this.hideBanner();
          }
        }
      }
    }
  }

  private registerSaveListener() {
    this.denySaveLink?.addEventListener("click", () => this.onDenySave());
    this.acceptSaveLink?.addEventListener("click", () => this.onAcceptSave());
  }

  private onDenySave() {
    this.hideBanner();
    this.emptyBanner();

    SizesPersonalizationFilterSheet.activityTracking("no_save_facet_value", this.facetId || "");
  }

  private onAcceptSave() {
    eventQBus.emit("heureka.filterPersonalization.save", {
      values: this.valueIds,
    });

    this.emptyBanner();
    this.hideBanner();

    SizesPersonalizationFilterSheet.activityTracking("save_facet_value", this.facetId || "");
  }

  private registerFacetValueResizeObserver(lastClickedFacetValue: FacetValue) {
    if (this.bannerResizeObserver != null) {
      this.bannerResizeObserver.unobserve(this.banner);
    }
    this.bannerResizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        let bannerPrecedingFacetValue = this.bannerPrecedingFacetValueId
          ? FacetValue.fromId(this.bannerPrecedingFacetValueId)
          : undefined;
        const bannerTop = bannerPrecedingFacetValue?.li?.getBoundingClientRect().top;
        const lastClickedTop = lastClickedFacetValue?.li?.getBoundingClientRect().top;
        const bannerPositionChanged = bannerTop !== lastClickedTop;

        /*                                     */
        if (bannerPositionChanged || bannerPrecedingFacetValue === undefined) {
          bannerPrecedingFacetValue?.removeBannerMargin();
          this.bannerPrecedingFacetValueId = lastClickedFacetValue?.id;
          bannerPrecedingFacetValue = lastClickedFacetValue;
        }

        if (bannerPrecedingFacetValue) {
          this.positionBannerAfterFacetValue(entry, bannerPrecedingFacetValue);
        }

        if (bannerPositionChanged) {
          entry.target.scrollIntoView({ block: "start", behavior: "smooth" });
        }
      }
    });
    this.bannerResizeObserver.observe(this.banner);
  }

  private positionBannerAfterFacetValue(entry: ResizeObserverEntry, facetValue: FacetValue) {
    const bannerHeight =
      entry.borderBoxSize.length > 0
        ? Math.max(entry.borderBoxSize[0].blockSize, entry.contentRect.height)
        : entry.contentRect.height;
    const bannerOffsetTop: number = 42 + facetValue.offsetTop!;
    this.banner.style.top = `${bannerOffsetTop}px`;
    facetValue.addBannerMargin(bannerHeight === 0 ? 0 : bannerHeight + 8);
  }

  private showBanner(): void {
    this.banner.hidden = false;
  }

  private hideBanner(): void {
    this.banner.hidden = true;
  }

  /*       */

  static sortValues(values: Array<string>) {
    const nonNumbers = values.filter((value) => isNaN(parseFloat(value))).sort();
    const sortedNumbers = values
      .filter((value) => !isNaN(parseFloat(value)))
      .sort((value1, value2) => parseFloat(value1) - parseFloat(value2));
    return sortedNumbers.concat(nonNumbers);
  }

  private static activityTracking(activity: TrackingLabels["san_FacetActivity"], facetId: string) {
    eventQBus.emit("tracking.bct.submitEvent", {
      san_FacetActivity: activity,
      san_FacetActivityType: facetId,
    });
  }

  private static updateFilterSheetTracking(values: string[]) {
    const labels = SizesPersonalization.savedFacetValuesTrackingLabels(values);
    FilterSheet.updateActiveSheetTracking("wk.san_SavedFacetValues", labels["wk.san_SavedFacetValues"]);
    FilterSheet.updateActiveSheetTracking("wk.san_SavedFacetValuesCount", labels["wk.san_SavedFacetValuesCount"]);
    FilterSheet.updateActiveSheetTracking("wk.san_FilterProfileCreated", labels["wk.san_FilterProfileCreated"]);
  }
}
