import { eventQBus } from "../types/EventQBus";
import { cloneElement, filterElements, moveAllChildren, notEmpty } from "../util/Utils";
import type { FilterSectionLoadedEvent } from "../multifiltering/FilterTypes";
import { HeurekaElementFactory } from "../util/HeurekaElementFactory";
import { Facet } from "../filter/Facet";

const ID_SMART_SORTING = "heureka_smartSorting";

export class SmartSorting {
  private static readonly factory = HeurekaElementFactory.byId(ID_SMART_SORTING, SmartSorting);

  /*               */
  constructor(
    private readonly elem: HTMLElement,
    private readonly matchedValues: HTMLInputElement[] = SmartSorting.match(elem)
  ) {}

  /*                  */

  static template(rootElement?: ParentNode | null) {
    return SmartSorting.factory.pick(undefined, rootElement);
  }

  /*               */

  static register() {
    eventQBus.on("heureka.filters.loaded", SmartSorting.initAll);
  }

  static initAll(event: FilterSectionLoadedEvent, rootElement?: ParentNode) {
    SmartSorting.template(rootElement)?.init();
  }

  protected init() {
    return this;
  }

  static prepareAll(fragment: DocumentFragment) {
    SmartSorting.template(fragment)?.prepare();
  }

  private prepare() {
    const oldTemplate = SmartSorting.template();
    if (oldTemplate) {
      this.values = oldTemplate.values;
    }
  }

  /*                       */

  private get values(): HTMLElement {
    return this.elem;
  }

  private set values(source) {
    moveAllChildren(source, this.elem, true);
  }

  get relevantValues(): HTMLInputElement[] {
    return this.matchedValues.map(cloneElement);
  }

  /*                 */

  /*       */

  private static match(elem: HTMLElement): HTMLInputElement[] {
    const facetName = elem.dataset.facetName;
    if (facetName) {
      const categoryFacet = Facet.facetId(facetName);
      const unSelectedFacetValues = categoryFacet?.unSelectedFacetValues
        .map((facetValue) => facetValue.input)
        .filter(notEmpty);
      if (unSelectedFacetValues) {
        return filterElements<HTMLInputElement>(
          "input",
          (input) => {
            return this.findMatchingElement(facetName, unSelectedFacetValues, input.value);
          },
          elem
        );
      }
    }
    return [];
  }

  private static findMatchingElement(
    facetName: string,
    unSelectedFacetValues: HTMLInputElement[],
    inputValue: string
  ): HTMLInputElement | undefined {
    return unSelectedFacetValues.find((input) => {
      return facetName === "kategorien" ? input.value.split(">")[0] === inputValue : input.value === inputValue;
    });
  }
}
