import { childElements, forEachElement, isProductList, moveAllChildren } from "../util/Utils";
import {
  filter,
  filterListMobile,
  filterSheetButton,
  filterSheetFooterTemplate,
  filterSheetTemplate,
  filterTitles,
  localNavigation,
  selectedNavigationTitle,
  sheetContent,
  sheetMenuBackBackButton,
  sheetMenuBar,
} from "./Selectors";
import { OverflowBox, OverflowBoxContainer } from "../overflowBox/OverflowBox";
import { Expander, initializeExpandersIn } from "../expander/Expander";
import { fireFilterSubmit, hasChanges } from "../filter/FilterFormActions";
import PartialLock from "../util/PartialLock";
import { FilterAccordion } from "../filter/FilterAccordion";
import { readSanPopularFacetValues, updateSanPopularFacetValues } from "../filter/PopularFacetValues";
import { getActiveSheet } from "../util/NonModuleFunctions";
import { FilterTitle } from "../filter/FilterTitle";
import type { SheetAPI } from "@otto-ec/global-pattern/types/700_Layer_und_Popup/400_pl_sheet/sheetTypes";
import type { Sheet } from "@otto-ec/global-pattern";
import { createSheetTemplate } from "./PaliSheet";
import { FilterSection } from "../filter/FilterSection";
import { FilterTitles } from "../filter/FilterTitles";
import { Filter } from "../filter/Filter";
import type { TrackingLabels } from "../tracking/TrackingLabels";
import { updateLabels, updateLegacyLabel } from "../tracking/FeatureTracking";
import { FacetValue } from "../filter/FacetValue";
import { setChangeLabels } from "../filter/FilterTracking";
import { isActiveVariation } from "../experiment/Experiments";

const FACET_CONTENT_SELECTOR = "ul.find_facet__content";
export const FIRST_LEVEL_CHANGE_TRACKING_LABELS: Partial<TrackingLabels> = {
  san_FacetLocation: "first_level",
};
export const SECOND_LEVEL_CHANGE_TRACKING_LABELS: Partial<TrackingLabels> = {
  san_FacetLocation: "second_level",
};
export const SIDEBAR_CHANGE_TRACKING_LABELS: Partial<TrackingLabels> = {
  san_FacetLocation: "sidebar",
};

export default class FilterSheet {
  private static recentDetailedFilterId: string | undefined;
  private constructor(private readonly sheet?: Sheet | SheetAPI) {}

  public static create() {
    return new FilterSheet(FilterSheet.newSheet());
  }

  public static declare() {
    return new FilterSheet(getActiveSheet());
  }

  private static newSheet() {
    if (isActiveVariation("e3213", "TestGroup") && !isProductList()) {
      return createSheetTemplate(filterListMobile(), filterSheetFooterTemplate());
    } else {
      return createSheetTemplate(filterTitles(), filterSheetFooterTemplate());
    }
  }

  setSheet() {
    let content;
    if (isActiveVariation("e3213", "TestGroup") && !isProductList()) {
      content = filterListMobile();
    } else {
      content = filterTitles();
    }

    if (content && this.sheet) {
      const footer = filterSheetFooterTemplate();
      this.sheet.removeBackButton();
      this.sheet.setContent(content);
      this.sheet.setMenuBar(childElements(footer) || []);
    }
    return this;
  }

  initializeSheet(updateChangeTracking = true, recentFilterId?: string) {
    const elem = filterSheetButton();
    if (elem && this.sheet) {
      const { scrollOffset: scrollOffset } = elem.dataset;
      const content = this.sheet.getContent();

      content.scrollTop = scrollOffset ? Number(scrollOffset) : 0;
      updateSanPopularFacetValues(content, readSanPopularFacetValues());

      FilterSheet.moveDesktopInlinedFiltersToSheet(content, updateChangeTracking);
      OverflowBox.initAll(undefined, content);
      initializeExpandersIn(content);

      FilterSheet.scrollToRecentFilter(recentFilterId);
    }
    return this;
  }

  public static scrollToRecentFilter(recentFilterId?: string) {
    FilterSheet.recentDetailedFilterId = recentFilterId || FilterSheet.recentDetailedFilterId;
    if (FilterSheet.recentDetailedFilterId) {
      const filterTitle = Filter.filterId(FilterSheet.recentDetailedFilterId)?.filterTitle?.elem;
      const content = FilterSheet.declare().sheet?.getContent();
      if (filterTitle && content) {
        const footerHeight = FilterTitle.footer(content)?.elem.clientHeight || 0;
        const footerTop = content.scrollTop + content.clientHeight + content.clientTop - footerHeight;

        if (filterTitle.offsetTop + filterTitle.clientHeight >= footerTop && filterTitle.scrollIntoView) {
          filterTitle.scrollIntoView(true);
        }
      }
    }
  }

  public lockContent() {
    if (this.sheet) {
      this.sheet.getContent().classList.add("pl_sheet__content--loading");
    }
    return this;
  }

  public unlockContent() {
    if (this.sheet) {
      this.sheet.getContent().classList.remove("pl_sheet__content--loading");
    }
    return this;
  }

  public open() {
    if (this.sheet) {
      FilterSheet.recentDetailedFilterId = undefined;
      this.sheet.open();
    }
    return this;
  }

  public close() {
    if (this.sheet) {
      this.sheet.close();
    }
  }

  public ifOpen(callback: (sheet: Sheet | SheetAPI) => void) {
    if (this.sheet) {
      callback(this.sheet);
    }
  }

  isOpen() {
    return !!this.sheet;
  }

  deactivate() {
    const source = sheetContent();
    if (source) {
      delete source.dataset.filterId;
      delete source.dataset.tsLabels;

      FilterSheet.closeAllTooltips(source);
      FilterSheet.fireDelayedActionsNowOrNever();
      FilterSheet.moveInlinedFiltersToFilterSectionFrom(source, true);
    }

    moveAllChildren(source, filterSheetTemplate(), true);
    moveAllChildren(sheetMenuBar(), filterSheetFooterTemplate(), true);
  }

  public static addLocalNavigationTitleValue() {
    const title = selectedNavigationTitle(localNavigation());
    const titles = filterTitles();
    if (titles && title) {
      const { overlayEntryTitle = "" } = title.dataset;
      forEachElement(".js_selectedNavigationFilterSheet", (elem) => (elem.innerText = overlayEntryTitle), titles);
    }
  }

  public static addInitialLoadError() {
    const errorMessage = FilterSection.template()?.errorMessage;
    if (errorMessage) {
      FilterTitles.template()?.elem.append(errorMessage.cloneNode(true));
    }
  }

  public static addErrorsOnFilterTitleValues(filterIds?: string[]) {
    const titles = filterTitles();
    if (titles) {
      const invalidIds = filterIds || FilterAccordion.invalidFilterIds();

      invalidIds.forEach((filterId) =>
        forEachElement(
          `.find_filterTitles__item[data-filter-id="${filterId}"]`,
          (item) => item.classList.add("find_filterTitles__item--invalid"),
          titles,
        ),
      );
    }
  }

  public static initPartialLocking() {
    const titles = filterTitles();
    const lockSelector = ".find_filterTitles__item, .heureka_filterTitle";
    /*                               */
    if (titles) {
      PartialLock.create(
        titles,
        lockSelector,
        (lockOwner) => {
          const filterId = lockOwner.dataset.filterId;
          const filterForm = filterId && filter(filterId);
          if (filterForm) {
            filterForm.delayedAction?.nowOrNever();
            return hasChanges(filterForm);
          }
          return false;
        },
        {
          unlockTopics: [
            "heureka.filterSection.loadAborted",
            "heureka.filters.loadAborted",
            "ftfind.dresonRule.resolveAborted",
          ],
        },
      );
    }
  }

  public static fireDelayedActionsNowOrNever() {
    const titles = filterTitles();
    if (titles) {
      FilterTitle.factory.forEach((filterTitle) => filterTitle.filter?.form?.delayedAction?.nowOrNever());
    }
  }

  public setScrollOffset(offset?: number) {
    if (typeof offset === "number") {
      const content = this.sheet?.getContent();
      if (content) {
        content.scrollTop = offset;
      }
    }
    return this;
  }

  private static moveDesktopInlinedFiltersToSheet(content: HTMLElement, updateChangeTracking: boolean) {
    FilterTitle.factory.forEach((filterTitle) => {
      if (updateChangeTracking) {
        Filter.updateChangeTrackingLabels(filterTitle.filterId, FIRST_LEVEL_CHANGE_TRACKING_LABELS);
      }
      filterTitle.attachFilter();
    });

    OverflowBoxContainer.forEachOverflowBoxContainer(
      (overflowBoxContainer) => overflowBoxContainer.selectAndSetContent(FACET_CONTENT_SELECTOR),
      content,
    );

    Expander.forEachExpander((expander) => expander.selectAndSetContent(FACET_CONTENT_SELECTOR), content);
  }

  private static moveInlinedFiltersToFilterSectionFrom(content: HTMLElement, updateChangeTracking: boolean) {
    OverflowBoxContainer.forEachOverflowBoxContainer(
      (overflowBoxContainer) => overflowBoxContainer.removeContent(),
      content,
    );
    Expander.forEachExpander((expander) => expander.removeContent(), content);

    FilterTitle.factory.forEach((filterTitle) => {
      if (updateChangeTracking) {
        Filter.updateChangeTrackingLabels(filterTitle.filterId, SIDEBAR_CHANGE_TRACKING_LABELS);
      }
      filterTitle.detachFilter();
    });
  }

  private static closeAllTooltips(sheetContent: HTMLElement) {
    /*                                                                                          */
    forEachElement(
      ".pl_tooltip--bottom .pl_icon",
      (tooltip) => tooltip.dispatchEvent(new Event("click", { bubbles: true })),
      sheetContent,
    );
  }

  public static updateActiveSheetTracking<T extends keyof TrackingLabels>(label: T, value?: TrackingLabels[T]) {
    const content = getActiveSheet()?.getContent();
    if (content) {
      updateLegacyLabel(content, label, value);
    }
    const backButton = getActiveSheet()?.getBackButton();
    if (backButton) {
      updateLegacyLabel(backButton, label, value);
    }
    const menuButton = sheetMenuBackBackButton();
    if (menuButton) {
      updateLegacyLabel(menuButton, label, value);
    }
  }

  public static selectFacetValues(facetValueIds: string[], trackingLabels: Partial<TrackingLabels>) {
    const inputs = facetValueIds.map((f) => FacetValue.fromId(f)).flatMap((f) => (f?.input ? [f.input] : []));
    if (inputs.length > 0) {
      const filterForm = inputs[0].form;
      if (filterForm) {
        inputs.forEach((i) => {
          i.checked = true;
          updateLabels(i, trackingLabels);
        });
        setChangeLabels(filterForm, trackingLabels);
        fireFilterSubmit(filterForm);
      }
    }
  }
}
