import type { OnFilterSheetClosed } from "./FilterSheetTopics";
import { submitAllAsAction, updateFeatureStatus } from "../filter/FilterTracking";
import type { TrackingLabels } from "../tracking/TrackingLabels";
import { eventQBus } from "../types/EventQBus";
import { forEachElement } from "../util/Utils";
import { createEventMergeId, deleteEventMergeId, submitEvent } from "../tracking/TrackingUtils";
import { sheetHeader } from "./Selectors";
import { SelectAllButton } from "../filter/form/SelectAllButton";
import StringHelper from "../util/StringHelper";
import type { SheetCloseTypes } from "@otto-ec/global-pattern";
import { event } from "@otto-ec/global-resources/event";

export function sanFoldout(closeType: SheetCloseTypes): TrackingLabels["san_Foldout"] {
  switch (closeType) {
    case "closedWithX":
      return "close_WithX";
    case "closedWithCurtainClick":
      return "close_filter_WithCurtainClick";
    case "closedWithDrag":
      return "close_filter_WithDrag";
    default:
      return "close_filter";
  }
}

function onFilterSheetOpened() {
  updateFeatureStatus("loaded", "visible", [
    "#find_filterSection",
    ".pl_sheet--open .pl_sheet__content .heureka_filterTitle:not(heureka_filterTitle--onlyTitle)",
  ]);
  submitAllAsAction("open", deleteEventMergeId());
}

function onFilterDetailSheetOpened() {
  const eventMergeId = deleteEventMergeId();
  const selectAll = !!SelectAllButton.factory.all(sheetHeader()).find((btn) => btn.isSelectAll());
  submitEvent({ san_FacetSelectAll: StringHelper.toBooleanString(selectAll) }, eventMergeId);
}

function onFilterSheetClosed(event: OnFilterSheetClosed) {
  const { tracking } = event;
  const eventMergeId = deleteEventMergeId();

  if (tracking) {
    submitEvent(tracking, eventMergeId);
  }
}

function onFilterSectionLoaded() {
  forEachElement(".js_openFilterSheet", (elem) => {
    elem.addEventListener("click", onClickStartTsLabels.bind(elem), {
      passive: true,
    });
  });
}

function onClickStartTsLabels(this: HTMLElement) {
  const { tsLabels, tsSingle } = this.dataset;
  if (tsLabels) {
    submitEvent(JSON.parse(tsLabels), tsSingle ? undefined : createEventMergeId());
  }
}

/**
 *
 *
 *
 *
 *
 */
export function registerClickTracker(
  rootElement?: HTMLElement | keyof HTMLElementTagNameMap | string,
  options?: boolean | AddEventListenerOptions
) {
  /*                                                                   */
  event.delegate(
    rootElement || (document as unknown as HTMLElement),
    "click",
    ".js_filterSheetAction",
    onClickStartTsLabels as (this: Element) => void,
    options as unknown as boolean
  );
}

export function registerFilterSheetTracking() {
  eventQBus.on("ftfind.filterSheet.opened", onFilterSheetOpened);
  eventQBus.on("ftfind.filterSheet.closed", onFilterSheetClosed);

  eventQBus.on("ftfind.filterSheet.openedDetail", onFilterDetailSheetOpened);
  eventQBus.on("ftfind.filterSheet.closedDetail", deleteEventMergeId);

  eventQBus.on("ftfind.filterSheet.openedNavigation", deleteEventMergeId);
  eventQBus.on("ftfind.filterSheet.closedNavigation", deleteEventMergeId);

  eventQBus.on("heureka.filters.loaded", onFilterSectionLoaded);
}
